export default class ButtonProcessingState {
    private $event;
    private target;
    private clone;
    private page_show_callback;

    constructor($event) {
        this.$event = $event;

        if (this.$event) {
            this.target = $event.target;
        }

        if (this.target && this.target.tagName == 'I' && this.target.parentNode && (this.target.parentNode.tagName == 'A' || this.target.parentNode.tagName == 'BUTTON')) {
            this.target = this.target.parentNode;
        }

        this.page_show_callback = this.onPageShow.bind(this);
        window.addEventListener('pageshow', this.page_show_callback);
    }

    onPageShow(event) {
        if (event.persisted) {
            if (this.clone) {
                this.resolved();
            }
        }
    }

    public process(): void {
        if (!this.$event || !this.target) {
            return;
        }

        this.clone = document.createElement(this.target.tagName);
        this.clone.classList = this.target.classList;
        this.clone.setAttribute('disabled', 'disabled');
        this.clone.innerHTML = 'Processing';

        this.target.parentNode.replaceChild(this.clone, this.target);
    }

    public resolved(text?): void {
        if (!this.$event || !this.target || !this.clone) {
            return;
        }

        this.clone.parentNode.replaceChild(this.target, this.clone);
        this.clone = null;
        
        if (this.target && text) {
            this.target.innerHTML = text;
        }

        window.removeEventListener('pageshow', this.page_show_callback);
    }
}