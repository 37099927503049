import {Model, TModelDefinitionList} from '../../../../../common/ts/core/models/Model';
import {ProcessingUser} from './ProcessingUser';
import {ProcessingCompany} from './ProcessingCompany';

export default function registerModels():  void {
    const models: TModelDefinitionList = {
        ProcessingUser: ProcessingUser,
        ProcessingCompany: ProcessingCompany
    };

    Model.registerModels(models);
}