import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class InstructionDownload extends Model {
    static override uri: string = '/processing/api/v1/instruction-download/:id/';
    static override objects: APIResource<InstructionDownload>;

    @field()
    name: string;

    @field()
    file: string;

    @field()
    thumbnail: string;
}