import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {IModel} from '../IModel';
import {Services} from '../../services/Services';
import {Model} from '../Model';

/*
    Loads data from the api response into the model
 */
export function field(fieldType = Field, config = {}) {
    return function(target: Model, key: string) {
        const _key = key;
        key = key[0] == '_' ? key.substr(1) : key;

        if (target.__allFields__ == undefined) {
            target.__allFields__ = [];
        }

        // Abstract models share __fields__
        if (target.__allFields__.indexOf(key) === -1)
            target.__allFields__.push(key);

        const getter = function() {
            return [fieldType, config, _key !== key];
        };

        Object.defineProperty(target, `__${key}__`, {
            get: getter,
            set: function(v){},
            enumerable:false,
            configurable: true
        });
    };
}

type FieldLinkFunction = (obj: any) => any;
type FieldLink = string | FieldLinkFunction;
type LinkType = 'single'|'multiple';

/*
    @link(import_name, field, type?, post_load?)
    Lazy loads a linked resource when accessing the property. This is useful for data that is not stored directly
    on the model, or only loading content when it's needed. This data will not be included when saving. It also
    sets up syncing bindings, so we can tell vue when it needs to update the display.

    These are not included when posting data to the server.

    A simplified usage example is:

    @link('ClipartCategorySearchTerm', 'graphic')
    search_terms: APIResponse<AdminClipartCategorySearchTerm>;

    Which is equivalent to:

    get search_terms() {
        if (!this._search_terms) {
            this._search_terms = Services.get('ClipartCategorySearchTerm').objects.filter({graphic: this.id});
        }
        return this._search_terms;
    }
    set search_terms(v) {
        this._search_terms = v;
    }


    A function can also be provided as the field to handle more complex loading.

    @link('ClipartCategoryGraphic', (v: AdminClipart) => {return {object_id: v.id, type: 'clipart'}}, 'single')
    category_graphic: AdminClipartCategoryGraphic;

    Which is equivalent to:

    get category_graphic() {
        if (!this._category_graphic) {
            this._category_graphic = Services.get('ClipartCategoryGraphic').objects.filter({object_id: this.id, type: 'clipart'});
        }

        return this._category_graphic;
    }
    set category_graphic(v) {
        this._category_graphic = v;
    }
 */
export function link(import_name: string, field: FieldLink, type: LinkType = 'multiple', post_load?: (v: any) => void) {
    return function(target: Model, key: string) {
        let private_key = `_${key}`;

        const getter = function () {
            // The 'this' object is now an instance of the target.
            if (this[private_key]) {
                return this[private_key];
            }

            if (!this.$resolved) {
                return null;
            }

            let field_data = {};
            if (typeof field == 'string') {
                if (!this.id) {
                    return null;
                }

                field_data[field] = this.id;
            }
            else {
                field_data = field(this);
            }

            let qry;
            if (type == 'single') {
                qry = Services.get<typeof Model>(import_name).objects.get(field_data);

                qry.$promise.then(() => {
                    qry.bind('sync', () => {
                        this.trigger('sync');
                    });
                    this.trigger('sync');
                })
            }
            else {
                qry = Services.get<typeof Model>(import_name).objects.filter(field_data);
                qry.$promise.then(() => {
                    for (const item of qry.items) {
                        item.bind('sync', () => {
                            this.trigger('sync');
                        });
                    }
                    this.trigger('sync');
                })
            }

            qry.$promise.then(() => {
                if (post_load) {
                    post_load(this);
                }
                this.trigger('sync');
            });

            this[private_key] = qry;

            return this[private_key];
        }

        const setter = function (v) {
            this[private_key] = v;
        }

        Object.defineProperty(target, key, {
            get: getter,
            set: setter,
            enumerable:false,
            configurable: true
        });
    }
}

export interface IFieldConfig {
    [key: string]: any;
}

export interface IField {
    readonly model: IModel;
    value: any;
    validate(): string[];
}

export class Field extends EventDispatcher implements IField {
    protected _cleaned: any;
    protected _value: any;
    protected _errors: string[];
    protected config: IFieldConfig;
    public initial_value;

    constructor(
        public readonly model: IModel,
        value?: any,
        config?: IFieldConfig
    ) {
        super();
        this.model = model;
        this.config = config || {};
        this.value = value;
        this._errors = [];
    }

    set value(v: any) {
        this.setValue(v);
    }

    get value(): any {
        return this._value;
    }

    get cleaned(): any {
        return this._cleaned;
    }

    clean(): any {
        return this._value;
    }

    getPostData(): any {
        if (this.config['readOnly']) {
            return null;
        }

        return this._cleaned;
    }

    get skipValidation(){
        return !this.config['required'] && this._value == null;
    }

    protected initialValueSetup(v: any) {
        this.initial_value = v;
        return v;
    }

    protected setValue(v: any) {
        if (v === this._value)
            return;

        // If the value is getting set for the first time lets let sub classes parse it if need be
        if (this._value === undefined) {
            v = this.initialValueSetup(v);
        }

        const oldValue = this._value;
        this._value = v;
        this._cleaned = this.clean();
        this.trigger('change', {
            oldValue: oldValue,
            value: v
        });
    }

    protected getValue() {
        return this._value;
    }

    public validate(): string[] {
        this._errors = [];

        if (this.config['required'] === true && [null, undefined, ''].indexOf(this._value) > -1)
            this._errors.push('This field is required.');

        return this._errors;
    }
}
