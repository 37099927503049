export const html: string = `
<div class="modal" tabindex="-1" role="dialog" id="upload-work-order" aria-labelledby="upload-work-order">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" @click="close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel"><i class="fa fa-upload"></i> Upload Processed File</h4>
            </div>
            <div class="modal-body clearfix" v-if="processing">
                <p>Processing! Please wait...</p>
            </div>
            <div class="modal-body clearfix" v-if="!processing">
                <div class="form-group">
                    <error-list :errors="errors" field="__all__"></error-list>
                </div>
                <div class="col-xs-6">
                    <h4>Processed File</h4>
                    <error-list :errors="errors" field="file"></error-list>
                    <div class="drop-image-container" @drop.prevent="setupFile($event)" @dragover.prevent="" v-show="!success">
                        <i class="fa fa-image"></i>
                        <p> Drag file here to upload <br> or </p>
                        <div class="form-group" id="user-file-input">
                            <input class="btn-info" id="file-upload" @change="setupFile($event)" type="file">
                            <label class="btn btn-info" for="file-upload">Choose File</label>
                            <br>
                            <label v-if="file">{{ file.name }}</label>
                        </div>
                    </div>
                    <div class="upload-preview" v-show="success">
                        <img :src="verification_raster" v-show="verification_raster">
                    </div>
                </div>
                <div class="col-xs-6">
                    <h4>Customer Preview without Spot Color (optional)</h4>
                    <div class="drop-image-container" @drop.prevent="setupCustomerFile($event)" @dragover.prevent="" v-show="!success">
                        <i class="fa fa-image"></i>
                        <p> Drag file here to upload <br> or </p>
                        <div class="form-group" id="user-file-input">
                            <input class="btn-ghost" id="customer-file-upload" @change="setupCustomerFile($event)" type="file">
                            <label class="btn btn-info" for="customer-file-upload">Choose Customer Preview</label>
                            <br>
                            <label v-if="customer_file">{{ customer_file.name }}</label>
                        </div>
                    </div>
                    <div class="upload-preview" v-show="success">
                        <img :src="verification_customer_raster" v-show="verification_customer_raster">
                    </div>
                </div>
                <div class="col-xs-12 notes-area">
                    <h4>Note (optional)</h4>
                    <textarea v-show="!success" v-model="note"></textarea>
                    <p v-show="success">{{ note }}</p>
                </div>
            </div>
            <div class="modal-footer" v-show="!success">
                <span class="upload-footer-note float-left">Please make sure the size on the work order is set to the size of the Processed file BEFORE uploading.</span>
                <button class="btn btn-warning" @click="cancel()" :disabled="processing"><i class="fa fa-close"></i> Cancel</button>
                <button id="save-line-item" type="button" class="btn btn-primary" @click="save($event)" :disabled="processing"><i class="fa fa-upload"></i> Upload</button>
            </div>
            <div class="modal-footer" v-show="success">
                <span class="upload-footer-note float-left">Please make sure the size on the work order is set to the size of the Processed file BEFORE uploading.</span>
                <button class="btn btn-warning" @click="cancel()" :disabled="processing"><i class="fa fa-arrow-left"></i> Revise</button>
                <button id="save-line-item" type="button" class="btn btn-primary" @click="acceptUpload($event)" :disabled="processing"><i class="fa fa-save"></i> Approve</button>
            </div>
        </div>
    </div>
</div>
`;
