export class DuplicateFilterException extends Error {
    public override name = "DuplicateFilterException";
    constructor (message) {
        super(message);
    }
}

export class DuplicateServiceException extends Error {
    public override name = "DuplicateServiceException";
    constructor (message) {
        super(message);
    }
}

export class UndefinedServiceException extends Error {
    public override name = "UndefinedServiceException";
    constructor (message) {
        super(message);
    }
}
