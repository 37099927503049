import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import axios from 'axios';
import {IPromise, SimplePromise} from '../utils/SimplePromise';

export class Http {
    constructor() {
        axios.defaults.xsrfCookieName = 'csrftoken';
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    }
    private handleRequest(promise): IPromise<AxiosResponse> {
        const deferred = SimplePromise.defer<AxiosResponse>();
        promise.then((response) => {
            deferred.resolve(response);
        }).catch((error) => {
            if (error.isAxiosError) {
                deferred.reject(error);
            }
            else {
                throw error;
            }
        })

        return deferred.promise;
    }
    request(config: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.request(config));
    }
    get(url: string, config?: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.get(url, config));
    }
    delete(url: string, config?: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.delete(url, config));
    }
    post(url: string, data?: any, config?: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.post(url, data, config));
    }
    put(url: string, data?: any, config?: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.put(url, data, config));
    }
    patch(url: string, data?: any, config?: AxiosRequestConfig): IPromise<AxiosResponse> {
        return this.handleRequest(Axios.patch(url, data, config));
    }
}
