import VueComponent, {method, prop} from '../../../../../common/ts/core/adapters/VueComponent';
import IVueComponent from '../../../../../common/ts/core/adapters/IVueComponent';
import {html} from './SuccessList.html';

class SuccessListController extends VueComponent {
    @prop()
    messages: string[];

    @method()
    messageList() {
        if (typeof this.messages == 'string') {
            return [this.messages];
        }

        return this.messages;
    }
}

export default function SuccessList(): IVueComponent {
    return {
        controller: SuccessListController,
        template: html,
        tag: 'success-list'
    }
}