export const html: string = `
<div class="dropdown color-dropdown" v-dropdown>
    <button class="btn dropdown-toggle dropdown-ghost btn-default" type="button">
        <div class="color default-display-item"><div class="color-square" :style="{'background': selected ? selected.background : noneColor, 'background-size': selected && selected.background ? 'cover' : null }"></div></div>
        <div class="text color-name default-display-item"><span class="dropdown-color-text">{{ getName(selected) }}</span></div>
        <input type="text" class="dropdown-search-input" v-model="query" v-on:keydown.down="focusFirstListElement($event)" :placeholder="getSelectedName(selected)" v-on:keydown.enter="autofillFromSelected($event)">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu" v-if="smallColorSquares">
        <li v-if="none && !hideNone" @click="select(null)" :class="{selected : selected == null}">
            <div class="color"><div class="color-square" :style="{'background': noneColor ? noneColor : 'white'}"></div></div>
            <div class="text"><span>{{ getName(null) }}</span></div>
        </li>
        
        <li v-for="item in items" @click="select(item);" :class="{selected : item == selected}">
            <div class="color"><div class="color-square" :style="{'background': item.background, 'background-size': item.background ? 'cover' : null }"></div></div>
            <div class="text"><span>{{ getName(item) }}</span></div>
        </li>
    </ul>
    <ul class="dropdown-menu color-tiles-container" v-if="!smallColorSquares">
        <li class="color-dropdown-tile" v-if="none && !hideNone" @click="select(null)" :class="{selected : selected == null}">
            <div class="color"><div class="color-square" :style="{'background': noneColor ? noneColor : 'white'}"></div></div>
            <div class="text"><span>{{ getName(null, true) }}</span></div>
        </li>
        
        <li class="color-dropdown-tile" v-for="item in items" @click="select(item);" :class="{selected : item == selected}">
            <div class="color"><div class="color-square" :style="{'background': item.background, 'background-size': item.background ? 'cover' : null }"></div></div>
            <div class="text"><span>{{ getName(item, true) }}</span></div>
        </li>
    </ul>
</div>
`;
