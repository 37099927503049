import {Field} from "./Field";
import {Services} from '../../services/Services';

export class ForeignField extends Field {
    private _relatedModel:any;

    get relatedModel():any {
        if ([null, undefined].indexOf(this._relatedModel) > -1) {
            if (typeof this.config.model === 'string')
                this._relatedModel = Services.get(this.config.model);
            else
                this._relatedModel = this.config.model;
        }
        return this._relatedModel;
    }

    override get value() {
        return this.getValue();
    }
}
