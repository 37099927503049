import {html} from './SimpleImageShadow.html';
import VueComponent, {computed, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';

export class SimpleImageShadowController extends VueComponent {
    @prop()
    image: string;

    @prop()
    noShadow: boolean;

    @prop()
    invert: boolean;

    @prop()
    color: string;

    @computed()
    shadow() {
        return `drop-shadow(0 0 1px rgba(${this.computed_color}, ${this.computed_color}, ${this.computed_color}, .4))`;
    }

    get computed_color() {
        return this.color ? this.color : '0';
    }
}


export default function SimpleImageShadow(): IVueComponent {
    return {
        controller: SimpleImageShadowController,
        template: html,
        tag: 'image-shadow'
    };
}
