import {field} from '../../../../../common/ts/core/models/fields/Field';
import {ForeignKeyField} from '../../../../../common/ts/core/models/fields/ForeignKeyField';
import {ForeignKeyURIField} from '../../../../../common/ts/core/models/fields/ForeignKeyURIField';
import {Model} from '../../../../../common/ts/core/models/Model';
import {ProcessingCompany} from '../../accounts/models/ProcessingCompany';
import {ProcessingUser} from '../../accounts/models/ProcessingUser';
import {Invoice} from './Invoice';
import {ServicePrepayment} from './ServicePrepayment';
import {WorkOrder} from '../../work_order/models/WorkOrder';
import {ProvidedService} from '../../work_order/models/ProvidedService';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class ServiceFulfillment extends Model {
    public static override readonly uri: string = '/payments/api/v1/service-fulfillment/:id/';
    static override objects: APIResource<ServiceFulfillment>;

    @field(ForeignKeyURIField, {
        model: 'Invoice'
    })
    invoice: ForeignKeyURIField<Invoice>;

    @field(ForeignKeyURIField, {
        model: 'ProcessingCompany'
    })
    company: ForeignKeyURIField<ProcessingCompany>;

    @field(ForeignKeyField, {
        model: 'ProcessingUser'
    })
    user: ProcessingUser;

    @field(ForeignKeyField, {
        model: 'ProvidedService'
    })
    service: ProvidedService;

    @field(ForeignKeyURIField, {
        model: 'WorkOrder'
    })
    work_order: ForeignKeyURIField<WorkOrder>;

    @field(ForeignKeyField, {
        model: 'ServicePrepayment'
    })
    prepayment: ServicePrepayment;

    @field()
    date_created: string;

    @field()
    prefixed_work_order_id: string;

    @field()
    amount_paid: number;

    public get work_order_id(): number {
        const field: ForeignKeyURIField<WorkOrder> = this.getField<ForeignKeyURIField<WorkOrder>>('work_order');

        return field ? field.id : null;
    }
}
