import {html} from './ApprovedWorkOrder.html';
import {WorkOrder} from '../../models/WorkOrder';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import VueComponent, {data} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import RouteService from '../../../../../../common/ts/core/services/RouteService';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import NamedRouteService from '../../../../../../common/ts/core/services/NamedRouteService';


class ApprovedWorkOrderController extends VueComponent {

    @data()
    work_orders: APIResponse<WorkOrder>;

    @data()
    limit;

    @data()
    paginator_url;

    @data()
    page;

    constructor(component) {
        super(component);

        let params = Services.get<RouteService>('RouteService').current.params;

        this.page = parseInt(params.get('page')) || 1;
        this.limit = 20;

        this.paginator_url = Services.get<NamedRouteService>('$NamedRouteService').reverse('work-orders:approved', {
            page: ':page'
        });

        let offset = (this.page - 1) * this.limit;

        this.work_orders = Services.get<typeof WorkOrder>('WorkOrder').objects.filter({
            status: WorkOrder.DONE,
            limit: this.limit,
            offset: offset,
            order_by: '-date_created'
        });
    }
}

export default function ApprovedWorkOrder(): IVueComponent {
    'use strict';

    return {
        controller: ApprovedWorkOrderController,
        template: html,
        tag: 'approved-work-orders'
    };
}
