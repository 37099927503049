import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';
import {ProcessingUser} from '../../accounts/models/ProcessingUser';
import {ForeignKeyField} from '../../../../../common/ts/core/models/fields/ForeignKeyField';
import {WorkOrder} from './WorkOrder';
import {WorkOrderFile} from './WorkOrderFile';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class WorkOrderNote extends Model {
    public static override uri: string = '/processing/api/v1/work-order-note/:id/';
    static override objects: APIResource<WorkOrderNote>;

    @field(ForeignKeyField, {
        model: 'WorkOrder'
    })
    work_order: WorkOrder;

    @field(ForeignKeyField, {
        model: 'WorkOrderFile'
    })
    work_order_file: WorkOrderFile;

    @field()
    note;

    @field(ForeignKeyField, {
        model: 'ProcessingUser'
    })
    user: ProcessingUser;

    @field()
    date_created: string;

    @field()
    last_updated: string;

    @field()
    staff: any;

    @field()
    staff_if: number;
}