import {TForeignKeyURI} from './fields/ForeignKeyURIField';
import {IModel} from './IModel';


export class Collection<T extends IModel> extends Array<T> {
    public $injected: any;

    constructor(...items: T[]) {
        super(...items);
        Object.setPrototypeOf(this, Collection.prototype);
    }

    getData() {
        // Returns an array of data from all of the models in the collection
        const data = [],
            items: T[] = this as any as T[];
        for (const item of items) {
            data.push(item.getData());
        }
        return data;
    }

    getPostData() {
        const data = [],
            items: T[] = this as any as T[];
        for (const item of items) {
            data.push(item.getPostData());
        }
        return data;
    }

    isModified(): boolean {
        const items: T[] = this as any as T[];
        for (const item of items) {
            if (item.isModified())
                return true;
        }
        return false;
    }

    getItemFromURI(resourceUri: TForeignKeyURI<T>): T {
        if (!resourceUri) {
            return null;
        }

        const items: T[] = this as any as T[];
        for (const item of items) {
            if (item.equals(resourceUri)) {
                return item;
            }
        }
    }

    getItemFromID(id: number): T {
        if (!id) {
            return null;
        }

        id = Number(id);
        return this.find(v => v.id == id);
    }

    contains(resourceUri: TForeignKeyURI<T>): boolean {
        const items: T[] = this as any as T[];
        for (const item of items) {
            if (item.equals(resourceUri))
                return true;
        }
        return false;
    }

    removeItemFromURI(resourceUri: TForeignKeyURI<T>): void {
        const items: T[] = this as any as T[];
        for (const item of items) {
            if (item.equals(resourceUri)) {
                items.splice(items.indexOf(item), 1);
                return;
            }
        }
    }

    removeItemFromID(id: number): T {
        id = parseInt(`${id}`);
        const items: T[] = this as any as T[];
        for (const item of items) {
            if (item[item.pkField] === id) {
                items.splice(items.indexOf(item), 1);
                return;
            }
        }
    }

    getItemIDs() {
        const ids: number[] = [],
            items: T[] = this as any as T[];
        for (const item of items) {
            ids.push(item[item.pkField]);
        }

        return ids;
    }

    distinct(): Collection<T> {
        let list = new Collection<T>();

        for (const item of this) {
            if (list.indexOf(item) == -1) {
                list.push(item);
            }
        }

        return list;
    }

    override indexOf(item: T, fromIndex?: number) {
        if (item && item.id) {
            return super.indexOf(this.getItemFromID(item.id), fromIndex)
        }

        return super.indexOf(item, fromIndex);
    }
}
