export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>Approved Work Orders</h1>
    </div>
</div>

<div class="main-container col-full">
    <work-order-item :work-order="item" v-for="item in work_orders.items"></work-order-item>
    
    <div class="col-xs-12">
        <paginator :total="work_orders.totalCount" :limit="limit" :page="page" :url="paginator_url" class="pull-right"></paginator>
    </div>
    
    <div class="col-xs-12" v-show="work_orders.totalCount == 0 && work_orders.$resolved">
        No accepted work orders
    </div>
</div>

<upload-work-order></upload-work-order>
<view-work-order-modal></view-work-order-modal>
<view-work-order-note></view-work-order-note>
<view-work-order-customer-image></view-work-order-customer-image>
<view-work-order-processed-image></view-work-order-processed-image>
`;