import IVueDirective from '../adapters/IVueDirective';

export default function ClickOutside(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                if (typeof binding.value !== 'function') {
                    console.error(`The click outside directive requires a binding to a function not ${binding.value}. This directive will be disabled.`)
                    return;
                }

                el.clickOutsideEvent = function(event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        binding.value(event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unmounted: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        },
        tag: 'click-outside'
    }
}