import VueComponent, {data, method, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';
import {html} from './Paginator.html';

export class PaginatorPage {
    private _url: string;
    public page: number;
    public showFirst: boolean;
    constructor(url: string, page: number, showFirst?: boolean) {
        this._url = url;
        this.page = Number(page);
        this.showFirst = showFirst;
    }

    get url(): string {
        if (!this._url) {
            return;
        }

        if (this._url.indexOf(':page') === -1) {
            const parts: string[] = this._url.split('?');
            const params: string[] = [];
            if (parts.length > 1) {
                for (const param of parts[1].split('&')) {
                    if (param.split('=')[0] === 'page') continue;
                    params.push(param);
                }
            }
            params.push(`page=${this.page}`);

            return `${parts[0]}?${params.join('&')}`;
        } else {
            return this._url
                .replace('%3A', ':')
                .replace(':page/', `${this.page}/`)
                .replace('=:page', `=${this.page}`);
        }

    }
}

export class PaginatorController extends VueComponent {
    @prop()
    page: number;

    @prop()
    url: string;

    @prop()
    limit: number;

    @prop({
        watch: true
    })
    total: number;

    @prop()
    showFirst: boolean;

    @prop()
    change: ($event, page: PaginatorPage) => void;

    @prop()
    limits: any;

    @prop()
    jumpto: any;

    @prop()
    key: string;

    /**
     * Should we show the previous link button? This is false we're on the
     * first page.
     */
    @data()
    public showPrevious: boolean;

    /**
     * Should we show the next link button? This is false when we're on the
     * last page.
     */
    @data()
    public showNext: boolean;

    /**
     * PaginatorPage array containing the numbered pages (i.e. 1,2,3,4)
     */
    @data()
    public pages: PaginatorPage[] = [];

    /**
     * Jump to first page link
     */
    @data()
    public first: PaginatorPage;

    /**
     * Jump to last page link
     */
    @data()
    public last: PaginatorPage;

    /**
     * Link to previous page
     */
    @data()
    public previous: PaginatorPage;

    /**
     * Link to next page
     */
    @data()
    public next: PaginatorPage;

    @data()
    pageCount: number;

    constructor(
        component
    ) {
        super(component);
        this.createPages();

        this.bind('change:props', this.createPages.bind(this));
    }

    reset() {
        this.pages = [];
    }

    changePage($event, pageTo: PaginatorPage) {
        if (this.change) {
            $event.preventDefault();

            this.change($event, pageTo);
            this.page = pageTo.page;
            this.createPages();
        }
    }

    getCurrentPage() {
        let current = null;
        this.pages.some((page) => {
            if (page.page == this.page_value) {
                current = page;
                return true;
            }
            return false;
        });
        return current;
    }

    createPages() {
        if (!this.pageCount && this.total && this.limit) {
            this.pageCount = Math.ceil(this.total / this.limit);
        }

        if (!this.isValid())
            return;

        this.showPrevious = this.page_value > 1;
        this.showNext = this.page_value < this.pageCount;

        this.first = new PaginatorPage(this.url, 1, this.showFirst);
        this.last = new PaginatorPage(this.url, this.pageCount, this.showFirst);

        if (this.showPrevious) {
            this.previous = new PaginatorPage(this.url, this.page_value - 1, this.showFirst);
        }

        if (this.showNext) {
            this.next = new PaginatorPage(this.url, this.page_value + 1, this.showFirst);
        }

        this.pages = [];
        for (let i = this.page_value - 3; i < this.page_value + 3; i++) {
            if (i > 0 && i <= this.pageCount) {
                this.pages.push(new PaginatorPage(this.url, i, this.showFirst));
            }
        }
    }

    /**
     * Can we build the paginator?
     * @returns {boolean}
     */
    @method()
    isValid(): boolean {
        return isFinite(this.page_value) &&
            this.page_value != null &&
            isFinite(this.pageCount) &&
            this.pageCount != null &&
            !!this.url;
    }

    addDefaultLimit() {
        if (!this.limits)
            this.limits = [];

        if (this.limit > 0 && this.limits.indexOf(this.limit) === -1) {
            this.limits.push(this.limit);
        }
        this.limits.sort((a, b) => {
            return a > b ? 1 : -1;
        });
    }

    get page_value() {
        return Number(this.page);
    }
}

export default function Paginator(): IVueComponent {
    return {
        controller: PaginatorController,
        template: html,
        tag: 'paginator'
    }
};