export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>Company Information</h1>
    </div>
</div>

<div class="main-container" v-if="company">
    <div class="col-xs-12 col-full">
        <h3>{{ company.name }}</h3>
    </div>
    
    <div class="col-xs-6 col-full-left"> 
        <div class="form-group">
            <label>Contact Name:</label>
            <input class="form-control" v-model="company.contact_name">
        </div>
        
        <div class="form-group">
            <label>Contact Phone:</label>
            <input class="form-control" v-model="company.contact_phone">
        </div>
        
        <div class="form-group">
            <label>Contact Cell:</label>
            <input class="form-control" v-model="company.contact_cell">
        </div>
        
        <div class="form-group">
            <label>Paypal Email:</label>
            <input class="form-control" v-model="company.paypal_email">
        </div>
        
        <div class="form-group">
            <error-list :errors="errors" field="__all__"></error-list>
            <success-list :messages="success"></success-list>
        </div>
        
        <button class="btn btn-success pull-right" @click="save($event)">Save</button>
    </div>
</div>
`;
