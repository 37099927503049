import {EventDispatcher} from 'simple-ts-event-dispatcher';

export default class InjectedService extends EventDispatcher {
    private readonly _injected:any[];

    constructor(...args) {
        super();
        this._injected = args;
        this._constructor();
    }

    protected _constructor() {}

    injected<T>(name): T {
        const $inject = (this.constructor as any).$inject as any[];
        for(const i in $inject) {
            if($inject[i] == name)
                return this._injected[i];
        }

        console.trace();
        console.error(`Unable to find dependency ${name}`);
    }
}
