import AccountGuest from './AccountGuest/AccountGuest';
import AccountLogin from './AccountLogin/AccountLogin';
import AccountLoginModal from './AccountLoginModal/AccountLoginModal';


export default function registerComponents(vue_app) {
    // Add all login components to core, its loaded on every page
    vue_app.registerComponent(AccountGuest);
    vue_app.registerComponent(AccountLogin);
    vue_app.registerComponent(AccountLoginModal)
}
