import {html} from './AccountGuest.html';
import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import MessageList from '../../../../core/utils/MessageList';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {processRequestError} from '../../../../core/utils/utils';

class AccountGuestController extends VueComponent {
    public static override $inject: string[] = [
        '$http'
    ];

    @data()
    errors: MessageList = new MessageList()

    @data()
    email: string;

    constructor(component, protected $http) {
        super(component);
    }

    @method()
    protected sendRequest() {
        // Use a form for submitting the data
        const form = new FormData()
        form.append('email', this.email);

        this.$http.request({
            data: form,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: '/account/api/guest-login/',
            method: 'POST'
        }).then((response) => {
            if (response.data.success === false) {
                this.errors = response.data.errors;
            }
            else {
                window.location.href = '/checkout/'
            }
        }, (error) => {
            this.errors = processRequestError(error);
        });
    }
}

export default function AccountGuest(): IVueComponent {
    return {
        controller: AccountGuestController,
        template: html,
        tag: 'account-guest'
    };
}
