import {field} from '../../../../../common/ts/core/models/fields/Field';
import {Model} from '../../../../../common/ts/core/models/Model';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class ProcessingCompany extends Model {
    static override uri: string = '/users/api/v1/processing-company/:id/';
    static override objects: APIResource<ProcessingCompany>;

    @field()
    name;

    @field()
    contact_name;

    @field()
    contact_email;

    @field()
    contact_phone;

    @field()
    contact_cell;

    @field()
    paypal_email;

    @field()
    date;

    @field()
    active;
}
