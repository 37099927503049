import VueApp from '../../../../../common/ts/core/adapters/VueApp';
import ApprovedWorkOrder from './ApprovedWorkOrder/ApprovedWorkOrder';
import NewWorkOrder from './NewWorkOrder/NewWorkOrder';
import PendingWorkOrder from './PendingWorkOrder/PendingWorkOrder';
import RejectWorkOrder from './RejectWorkOrder/RejectWorkOrder';
import UploadWorkOrder from './UploadWorkOrder/UploadWorkOrder';
import ViewWorkOrderModal from './ViewWorkOrderModal/ViewWorkOrderModal';
import ViewWorkOrderNote from './ViewWorkOrderNote/ViewWorkOrderNote';
import WorkOrderItem from './WorkOrderItem/WorkOrderItem';
import ViewWorkOrderProcessedFile from './ViewWorkOrderProcessedFile/ViewWorkOrderProcessedFile';
import ViewWorkOrderCustomerFile from './ViewWorkOrderCustomerFile/ViewWorkOrderCustomerFile';
import Home from './Home/Home';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(ApprovedWorkOrder);
    vue_app.registerComponent(NewWorkOrder);
    vue_app.registerComponent(PendingWorkOrder);
    vue_app.registerComponent(RejectWorkOrder);
    vue_app.registerComponent(UploadWorkOrder);
    vue_app.registerComponent(ViewWorkOrderProcessedFile);
    vue_app.registerComponent(ViewWorkOrderCustomerFile);
    vue_app.registerComponent(ViewWorkOrderModal);
    vue_app.registerComponent(ViewWorkOrderNote);
    vue_app.registerComponent(WorkOrderItem);
    vue_app.registerComponent(Home);
}