import {html} from './RejectWorkOrder.html';
import MessageList from '../../../../../../common/ts/core/utils/MessageList';
import {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import WorkOrderService from '../../services/WorkOrderService';
import {Http} from '../../../../../../common/ts/core/services/Http';
import {ModalController} from '../../../../../../common/ts/core/adapters/ModalComponent';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';

class RejectWorkOrderController extends ModalController {

    @data()
    reason: string = '';

    @data()
    errors: MessageList = new MessageList();

    constructor(component) {
        super(component, 'reject-work-order-modal');
    }

    @method()
    submitRejection($event) {
        this.errors = new MessageList();

        if (!this.reason || this.reason.trim() == '') {
            this.errors.add('reason', 'This field is required');
        }
        if (!Services.get<WorkOrderService>('WorkOrderService').work_order) {
            return this.errors.add('__all__', 'Unknown error has occurred, please close the modal and try again.');
        }

        Services.get<Http>('$http').request({
            method: 'POST',
            data: {
                'work_order_id': Services.get<WorkOrderService>('WorkOrderService').work_order.id,
                'reason': this.reason
            },
            url: '/processing/api/reject-work-order/'
        }).then((response) => {
            if (!response.data) {
                this.errors.add('__all__', 'An unknown error has occurred');
                return;
            }

            if (!response.data.success) {
                this.errors.add('__all__', response.data.reason);
            }
            else {
                this.close()

                Services.get<WorkOrderService>('WorkOrderService').work_order.reload();
                Services.get<WorkOrderService>('WorkOrderService').trigger('status-change');
                this.reason = '';
            }
        }, (response) => {
            this.errors.add('__all__', 'An unknown error has occurred');
        });
    }
}

export default function RejectWorkOrder(): IVueComponent {
    return {
        controller: RejectWorkOrderController,
        template: html,
        tag: 'reject-work-order'
    };
}