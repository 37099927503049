import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {APIResource} from '../../../core/models/APIResource';

export class UserInStoreCreditTransaction extends Model {
    static override uri:string = '/profile/api/user/user-in-store-credit-transaction/:id/';
    static override objects: APIResource<UserInStoreCreditTransaction>;

    @field()
    action:number;

    @field()
    balance_change:number;

    @field()
    balance_end:number;

    @field()
    balance_start:number;

    @field()
    comment:string;

    @field()
    date_created:string;

    @field()
    transaction_type:number;
}
