import {html} from './CompanyDetail.html';
import {ProcessingCompany} from '../../models/ProcessingCompany';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';
import MessageList from '../../../../../../common/ts/core/utils/MessageList';
import VueComponent, {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import {processRequestError} from '../../../../../../common/ts/core/utils/utils';

class CompanyDetailController extends VueComponent {

    @data()
    errors: MessageList;

    @data()
    success: string[];

    @data()
    company: ProcessingCompany;

    constructor(component) {
        super(component);

        let response = Services.get<typeof ProcessingCompany>('ProcessingCompany').objects.all();
        response.$promise.then(() => {
            if (response.items.length > 0) {
                this.company = response.items[0];
            }
        });
    }

    @method()
    save($event) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.errors = new MessageList();
        this.success = [];

        this.company.save().then(() => {
            this.success.push('Successfully update company information');
            state.resolved();
        }, (error) => {
            this.errors = processRequestError(error);
            this.errors.add('__all__', 'Failed to save company information');
            state.resolved();
        });
    }
}

export default function CompanyDetail(): IVueComponent {
    return {
        controller: CompanyDetailController,
        template: html,
        tag: 'company-detail'
    };
}
