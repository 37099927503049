import {WorkOrderFile} from '../../models/WorkOrderFile';

export const html: string = `
<div class="modal" tabindex="-1" role="dialog" id="view-work-order" aria-labelledby="view-work-order">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="work_order_service.work_order_file">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel"><i class="fa fa-upload"></i>View Processed File</h4>
            </div>
            <div class="modal-body clearfix">
                <div class="col-xs-6">
                    <h4>Processed File</h4>
                    <img :src="work_order_service.work_order_file.raster ? work_order_service.work_order_file.raster : '/static/img/processing/no-processed-file.svg'">
                </div>
                <div class="col-xs-6">
                    <h4>Customer Preview without Spot Color (optional)</h4>
                    <img :src="work_order_service.work_order_file.customer_raster ? work_order_service.work_order_file.customer_raster : '/static/img/processing/no-processed-file.svg'">
                </div>
                <div class="col-xs-12" v-if="work_order_service.work_order_file_notes && work_order_service.work_order_file_notes.items.length > 0">
                    <hr>
                    <h4>Processed File Notes</h4>
                    <div class="work-order-note-item" :class="{last: index == work_order_service.work_order_file_notes.items.length}" v-for="(note, index) in work_order_service.work_order_file_notes.items">
                        <div class="col-xs-3 col-full-left">
                            <b v-if="note.user">{{ note.user.name ? note.user.name : note.user.email }}</b>
                            <b v-if="note.staff">{{ note.staff.staff_name ? note.staff.staff_name : note.staff.email }}</b>
                            <span>{{ $filters.date(note.date_created) }}</span>
                            <span>{{ note.user ? note.user.company_name : 'CarStickers Inc.' }}</span>
                        </div>
                        <div class="col-xs-9 note-comment">
                            {{ note.note }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info" @click="restore()" 
                    v-show="work_order_service.work_order_file.status == ${WorkOrderFile.REMOVED}">
                    Restore
                </button>
                <button class="btn btn-danger" @click="remove()" 
                    v-show="work_order_service.work_order_file.status == ${WorkOrderFile.PENDING} || work_order_service.work_order_file.status == ${WorkOrderFile.NEEDS_APPROVAL}">
                    Remove
                </button>
                <button class="btn btn-success" @click="approve()"
                    v-show="work_order_service.work_order_file.status == ${WorkOrderFile.NEEDS_APPROVAL}">
                    Approve    
                </button>
                <a class="btn btn-primary pull-left" :href="$filters.route('work-orders:download-processed-file', {id: work_order_service.work_order_file.id})" target="_blank">Download</a>
            </div>
        </div>
    </div>
</div>
`;
