import VueApp from '../../../../common/ts/core/adapters/VueApp';
import {data, method} from '../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../common/ts/core/services/Services';
import {Http} from '../../../../common/ts/core/services/Http';
import CookieManager from '../../../../common/ts/core/services/CookieManager';
import {LocalStorage} from '../../../../common/ts/core/services/LocalStorage';

export default class DashboardNav extends VueApp {

    @data()
    counts: {[key: string]: number} = {};

    @data()
    menu: any = {};

    @data()
    file_preview: File;

    @data()
    open: boolean;

    // 10 minutes
    interval = 1000 * 60 * 2;

    override mounted() {
        super.mounted();

        this.fetchSectionCounts();
        setInterval(this.fetchSectionCounts.bind(this), this.interval);
    }

    @method()
    toggleCompressed() {
        let el = document.getElementsByTagName('body')[0]
        el.classList.toggle('compressed');

        Services.get<typeof CookieManager>('$cookies').put('dashboard-compress', el.classList.contains('compressed') ? 'yes' : 'no');
    }

    @method()
    selectionCount(section) {
        return this.counts[section] ? Number(this.counts[section]) : 0;
    }

    fetchSectionCounts() {
        let counts = Services.get<LocalStorage>('LocalStorage').get('section-counts');
        if (counts) {
            this.counts = counts;
        }
        else {
            Services.get<Http>('$http').request({
                url: '/dashboard/api/section-counts/',
                method: 'GET'
            }).then((response) => {
                this.counts = response.data;
                // Set the interval to be 60 seconds less than the timeout, so it won't be loaded in the next cycle in case of a long request time
                Services.get<LocalStorage>('LocalStorage').set('section-counts', this.counts, new Date(new Date().getTime() + this.interval - (1000 * 60)))
            });
        }
    }
}