import {Model} from '../models/Model';
import APIResponse from '../models/APIResponse';
import {Http} from './Http';
import {Services} from './Services';
import {EventDispatcher} from 'simple-ts-event-dispatcher';

interface EventModelInterface {
    model: string;
    single: boolean;
    data: any; // A single dehydrated model or a api response of multiple models
}

interface EventInterface {
    event: string;
    data: any;
    models: EventModelInterface[];
}

export class SocketEvent {
    public name;
    public data: any;
    public models: any;
    private parent: SocketEventHandler;

    constructor(parent, message: EventInterface) {
        this.parent = parent;
        this.name = message.event;
        this.data = message.data;
        this.models = this.parseModels(message.models);
    }

    public modelSet<T extends Model>(name: string): APIResponse<T> {
        for (let data of this.models) {
            if (data.model == name) {
                return data.data;
            }
        }

        return null;
    }

    public modelInstance<T extends Model>(name: string): T {
        for (let data of this.models) {
            if (data.model == name) {
                return data.data;
            }
        }

        return null;
    }

    private parseModels(models: EventModelInterface[]) {
        if (!models) {
            return [];
        }

        const parsed_data = [];
        for (let model_data of models) {
            if (model_data.model && Services.isRegistered(model_data.model)) {
                const model = Services.get<typeof Model>(model_data.model);
                if (model_data.single) {
                    model_data.data = new model(model_data.data);
                }
                else {
                    model_data.data = model.objects.from(model_data.data);
                }
            }
            parsed_data.push(model_data)
        }

        return parsed_data;
    }
}

export default abstract class SocketEventHandler extends EventDispatcher {
    protected abstract socket_url: string;
    protected socket: WebSocket;
    private _connecting: boolean;
    private event_queue: any[];

    constructor() {
        super()
        this.event_queue = [];
        this.bindEvents();
    }

    abstract bindEvents();
    protected onSocketOpen(){}

    protected onSocketClose(e) {
       console.error(`Socket closed unexpectedly with code ${e.code}, reason: "${e.message}"`);
       console.error(e);
       this._connecting = false;
    }

    public sendEvent(event, data) {
        let event_data = {
            'event': event,
            'data': data
        };

        if (!this.socket) {
            this.connect();
            this.event_queue.push(event_data);
            return;
        }

        if (this.socket.readyState == WebSocket.OPEN) {
            this.sendEventData(event_data);
        }
        else {
            this.event_queue.push(event_data);
        }
    }

    public connect() {
        if (this.socket || this._connecting) {
            return;
        }

        this._connecting = true;

        Services.get<Http>('$http').request({
            url: '/chat/authorize/',
            method: 'POST',
            data: {}
        }).then(this.sessionAuthSuccess.bind(this))
          .catch(this.sessionAuthFailure.bind(this));
    }

    public disconnect() {
        this.socket.close();
        this.trigger('disconnect');
    }

    private sessionAuthSuccess() {
        let url = (location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + this.socket_url;
        this.socket = new WebSocket(
            url
        );

        this.socket.onmessage = this.onSocketMessage.bind(this);
        this.socket.onclose = this.onSocketClose.bind(this);
        this.socket.onopen = this.initSocket.bind(this);

        this._connecting = false;
    }

    private sessionAuthFailure() {
        this._connecting = false;
    }

    private onSocketMessage(e) {
        const message = JSON.parse(e.data);

        // Automatically populate models if they are injected in the service
        if (message.event) {
            this.trigger(message.event, new SocketEvent(this, message));

            // Trigger a sync event for vue so all components using this in data will update
            this.trigger('sync');
        }
        else {
            console.error('Unknown message type');
        }
    }

    private sendEventData(data) {
        this.socket.send(JSON.stringify(data));
    }

    private sendQueuedEvents() {
        for (let item of this.event_queue) {
            this.sendEventData(item);
        }
    }

    private initSocket() {
        this.onSocketOpen();
        this.sendQueuedEvents();
    }

    reconnect() {
        if (this.socket) {
            this.socket.close();
        }

        this.sessionAuthSuccess();

        // todo: somehow register all events again, store? notify?
    }

    get connected() {
        return !!(this.socket && this.socket.readyState == WebSocket.OPEN);
    }

    get connecting() {
        return this._connecting || (this.socket && this.socket.readyState == WebSocket.CONNECTING);
    }

    get disconnected() {
        return !this.socket || this.socket && this.socket.readyState == WebSocket.CLOSING || this.socket.readyState == WebSocket.CLOSED;
    }
}