export const html: string = `
<div class="modal" tabindex="-1" role="dialog" id="view-work-order-note" aria-labelledby="view-work-order-note">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="work_order_service.work_order">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel"><i class="fa fa-upload"></i> Notes</h4>
            </div>
            <div class="modal-body clearfix" v-if="work_order_service.work_order_notes">
                <div class="work-order-note-item" v-for="note in work_order_service.work_order_notes.items">
                    <div class="col-xs-3 col-full-left">
                        <b v-if="note.user">{{ note.user.name ? note.user.name : note.user.email }}</b>
                        <b v-if="note.staff">{{ note.staff.staff_name ? note.staff.staff_name : note.staff.email }}</b>
                        <span>{{ $filters.date(note.date_created) }}</span>
                        <span>{{ note.user ? note.user.company_name : 'CarStickers Inc.' }}</span>
                    </div>
                    <div class="col-xs-7 note-comment">
                        {{ note.note }}
                    </div>
                    <div class="col-xs-2 col-full-right note-image-preview">
                         <img v-if="note.work_order_file && note.work_order_file.raster" :src="note.work_order_file && note.work_order_file.raster">
                    </div>
                </div>
                <div class="work-order-note-item new-note" v-show="!(work_order_service.work_order.is_done || work_order_service.work_order.is_canceled)">
                    <div class="col-xs-3 col-full-left">
                        <span>New Comment</span>
                    </div>
                    <div class="col-xs-9 col-full-right" v-if="new_note">
                        <textarea v-model="new_note.note"></textarea>
                        <button class="btn btn-success pull-right" @click="addNote($event)">Add</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning" type="button" data-dismiss="modal" aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>
`;
