import {WorkOrderNote} from '../models/WorkOrderNote';
import {EventDispatcher} from 'simple-ts-event-dispatcher';
import APIResponse from '../../../../../common/ts/core/models/APIResponse';
import {Services} from '../../../../../common/ts/core/services/Services';
import {WorkOrder} from '../models/WorkOrder';
import {WorkOrderFile} from '../models/WorkOrderFile';

export default class WorkOrderService extends EventDispatcher {
    public work_order_file: WorkOrderFile;
    public work_order: WorkOrder;

    private _work_order_notes: APIResponse<WorkOrderNote>;
    private _work_order_file_notes: APIResponse<WorkOrderNote>;

    public setup(work_order, work_order_file) {
        this.work_order = work_order;
        this.work_order_file = work_order_file;

        this._work_order_notes = null;
        this._work_order_file_notes = null;
    }

    public get work_order_notes() {
        if (!this._work_order_notes && this.work_order) {
            this._work_order_notes = Services.get<typeof WorkOrderNote>('WorkOrderNote').objects.filter({work_order: this.work_order.id});
            this._work_order_notes.$promise.then(() => {
                this.trigger('sync');
            });
        }

        return this._work_order_notes;
    }

    public get work_order_file_notes() {
        if (!this._work_order_file_notes && this.work_order_file) {
            this._work_order_file_notes = Services.get<typeof WorkOrderNote>('WorkOrderNote').objects.filter({work_order: this.work_order.id, work_order_file: this.work_order_file.id});
            this._work_order_file_notes.$promise.then(() => {
                this.trigger('sync');
            });
        }

        return this._work_order_file_notes;
    }

    public resetWorkOrderNotes() {
        this._work_order_notes = null;
        this._work_order_file_notes = null;
    }
}
