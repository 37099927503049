import {AnalyticsTracking} from '../Analytics';
import {Order} from '../../../apps/order/models/Order';
import APIResponse from '../../models/APIResponse';
import {OrderedItem} from '../../../apps/order/models/OrderedItem';

export default class FacebookTracker implements AnalyticsTracking {
    get facebook() {
        if (navigator && navigator.doNotTrack == '1') {
            return null;
        }

        return window['fbq'];
    }

    trackAddedProductToCart(product_id, price, quantity) {
        if (!this.facebook) {
            return;
        }

        this.facebook('track', 'AddToCart', {
            content_ids: [`p${product_id}`],
            content_type: 'product',
            value: price,
            currency: 'USD'
        });
    }

    trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>) {
        if (!this.facebook) {
            return;
        }

        let line_ids = [];
        for (const line of ordered_items.items) {
            line_ids.push({
                id: `p${line.product_sku}`,
                quantity: line.quantity,
                content_type: 'product',
                value: line.unit_price,
                currency: 'USD'
            });
        }

        this.facebook('track', 'Purchase', {
            content_ids: line_ids,
            content_type: 'product',
            value: order.orderTotal,
            currency: 'USD'
        });
    }

    trackEvent(category, action, label?, value?) {}
    trackPage(page: string, title?: string) {}

    trackProductPage(sku, default_price) {
        if (!this.facebook) {
            return;
        }

        this.facebook('track', 'ViewContent', {
            content_ids: [`p${sku}`],
            content_type: 'product',
            value: default_price,
            currency: 'USD'
        });
    }

}
