import {Model, TModelDefinitionList} from '../../../../../common/ts/core/models/Model';
import {InstructionDownload} from './InstructionDownload';
import {InstructionVideo} from './InstructionVideo';
import {WorkOrder} from './WorkOrder';
import {WorkOrderFile} from './WorkOrderFile';
import {WorkOrderNote} from './WorkOrderNote';
import {ProvidedService} from './ProvidedService';

export default function registerModels():  void {
    const models: TModelDefinitionList = {
        InstructionDownload: InstructionDownload,
        InstructionVideo: InstructionVideo,
        WorkOrder: WorkOrder,
        WorkOrderFile: WorkOrderFile,
        WorkOrderNote: WorkOrderNote,
        ProvidedService: ProvidedService
    };

    Model.registerModels(models);
}