import {modal} from './Modal';

export default function ScrollToBinding() {
    for (const item of document.querySelectorAll('[data-scroll-to]')) {
        item.addEventListener('click', () => {
            if (item.attributes['data-scroll-to']?.value) {
                document.getElementById(item.attributes['data-scroll-to'].value)?.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        })
    }
}
