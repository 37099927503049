import VueApp from '../../../../../common/ts/core/adapters/VueApp';
import CompanyDetail from './CompanyDetail/CompanyDetail';
import UserAdd from './UserAdd/UserAdd';
import UserDetail from './UserDetail/UserDetail';
import UserList from './UserList/UserList';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(CompanyDetail);
    vue_app.registerComponent(UserAdd);
    vue_app.registerComponent(UserDetail);
    vue_app.registerComponent(UserList);
}