export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>CarStickers Processing Home Page</h1>
        <span class="home-status-count" v-show="new_count >= 0">There are currently {{ new_count }} files that need to be processed.</span>
    </div>
</div>
<div class="main-container home-container">
    <div class="col-xs-8">
        <div class="col-xs-12 col-full section-area">
            <h2>Hello, {{ user_service.user.name || user_service.user.email }}</h2>
            <div v-show="work_orders.items.length > 0">
                <p>You are currently working on</p>
                <div class="col-xs-12 work-order-preview" v-for="work_order in work_orders.items">
                    <img :src="work_order.raster_url">
                    <span>
                        Work Order: {{ work_order.reference_id }}
                        <br>
                        {{ work_order.data.product_name }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-full section-area">
            <h2>Main Navigation</h2>
            <div class="col-xs-4" v-show="user_service.user.permission_processing">
                <a class="navigation-group" href="/processing/new/">
                    <img src="/static/img/home/new-work-order-icon.svg">
                    Accept New
                    <br>
                    Work Order
                </a>
            </div>
            <div class="col-xs-4" v-show="user_service.user.permission_processing">
                <a class="navigation-group" href="/processing/pending/">
                    <img src="/static/img/home/pending-files-icon.svg">
                    Pending
                    <br>
                    Files
                </a>
            </div>
            <div class="col-xs-4" v-show="user_service.user.permission_processing">
                <a class="navigation-group" href="/processing/approved/">
                    <img src="/static/img/home/accepted-files-icon.svg">
                    Approved
                    <br>
                    Files
                </a>
            </div>
            <div class="col-xs-4" v-show="user_service.user.permission_company">
                <a class="navigation-group" href="/profile/users/">
                    <img src="/static/img/home/users-icon.svg">
                    View
                    <br>
                    Users
                </a>
            </div>
            <div class="col-xs-4" v-show="user_service.user.permission_company">
                <a class="navigation-group" href="/profile/company/">
                    <img src="/static/img/home/company-icon.svg">
                    Company
                    <br>
                    Information
                </a>
            </div>
            <div class="col-xs-4" v-show="user_service.user.permission_invoice">
                <a class="navigation-group" href="/payments/invoices/">
                    <img src="/static/img/home/invoices-icon.svg">
                    View
                    <br>
                    Invoices
                </a>
            </div>
        </div>
    </div>
    <div class="col-xs-4 instructions-side-bar">
        <h2>Knowledgebase</h2>
        <div v-show="downloads.items.length > 0" class="instruction-group">
            <span>Downloadable Manuals</span>
            <hr>
            <a class="instruction-item" v-for="download in downloads.items" :href="download.file" target="_blank">
                <img :src="download.thumbnail">
                <span>{{ download.name }}</span>
            </a>
        </div>
        <br>
        <div v-show="videos.items.length > 0" class="instruction-group">
            <span>Instructional Video</span>
            <hr>
            <a class="instruction-item" v-for="video in videos.items" :href="'https://www.youtube.com/watch?v=' + video.youtube_id" target="_blank">
                <img :src="'https://i1.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg'">
                <span>{{ video.name }}</span>
            </a>
        </div>
    </div>
</div>
`;
