import {Route} from '../../../../common/ts/core/services/RouteService';


export const ProcessingRouteList: Route[] = [
    new Route('accounts:list', '/profile/users/'),
    new Route('accounts:detail', '/profile/users/detail/:id/'),
    new Route('accounts:add', '/profile/users/add/'),
    new Route('accounts:company', '/profile/company/'),
    new Route('payments:invoice-list', '/payments/invoices/'),
    new Route('payments:invoice-detail', '/payments/invoices/:id/'),
    new Route('work-orders:approved', '/processing/approved/'),
    new Route('work-orders:pending', '/processing/pending/'),
    new Route('work-orders:new', '/processing/new/'),
    new Route('work-orders:download-processed-file', '/processing/download-processed-file/')
];