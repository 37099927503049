import {field} from '../../../../../common/ts/core/models/fields/Field';
import {Model} from '../../../../../common/ts/core/models/Model';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class ProcessingUser extends Model {
    static override uri: string = '/users/api/v1/processing-user/:id/';
    static override objects: APIResource<ProcessingUser>;

    @field()
    date_joined: string;

    @field()
    email: string;

    @field()
    cell_number: string;

    @field()
    in_store_credit: number;

    @field()
    is_active: boolean;

    @field()
    last_login: string;

    @field()
    name: string;

    @field()
    phone: string;

    @field()
    title: string;

    @field()
    note: string;

    @field()
    company_name: string;

    @field()
    permission_active: boolean;

    @field()
    permission_company: boolean;

    @field()
    permission_invoice: boolean;

    @field()
    permission_processing: boolean;

}
