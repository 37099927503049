export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>User Management</h1>
    </div>
</div>

<div class="main-container">
    <h3>User List</h3>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Title</th>
                <th>Active</th>
                <th>Processing</th>
                <th>Invoices</th>
                <th>Manager</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in $filters.orderBy(users.items, 'id', true)">
                <td>
                    <a :href="$filters.route('accounts:detail', {id: user.id})">{{ user.email }}</a> 
                </td>
                <td>
                    {{ user.name }}
                </td>
                <td>
                    {{ user.title }}
                </td>
                <td>
                    <input type="checkbox" v-model="user.permission_active" @change="update(user)">
                </td>
                <td>
                    <input type="checkbox" v-model="user.permission_processing" @change="update(user)"> 
                </td>
                <td>
                    <input type="checkbox" v-model="user.permission_invoice" @change="update(user)">
                </td>
                <td>
                    <input type="checkbox" v-model="user.permission_company" @change="update(user)">
                </td>
            </tr>
        </tbody>
    </table>
    <a class="btn btn-success" :href="$filters.route('accounts:add')">Add New User</a>
</div>

<div class="main-container">
    <div class="col-xs-12 col-full">
        <h3>About The Permissions</h3>
    </div>
    <div class="col-sm-8 col-xs-12 col-full">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Permission</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>Active</b>
                    </td>
                    <td>
                        Marking a user as active will allow them to access the dashboard and view the home page. 
                        Disabling this will prevent them from accessing the dashboard even if they have other permissions.
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Processing</b>
                    </td>
                    <td>
                        Giving a user the processing permission will enable the <i>Pending</i> and <i>Approved</i> tab allowing 
                        them to process files.
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Invoices</b>
                    </td>
                    <td>
                        Giving a user the invoice permission will enable the <i>Invoices</i> tab allowing them to view and 
                        manage invoices along with payment information.  
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Manager</b>
                    </td>
                    <td>
                        Giving a user the manager permission will enable the <i>Users</i> and <i>Company</i> tab allowing them to 
                        add, remove and change user permissions. It will also allow to change the company information.  
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
`;
