export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>Invoice {{ invoice.id }}</h1>
    </div>
</div>

<div class="main-container">
    <div class="col-xs-12 invoice-details">
        <div class="col-xs-6 col-md-3 stat">
            <span>Transaction Number</span>
            {{ invoice.transaction_id }}
        </div>
         <div class="col-xs-6 col-md-3 stat">
            <span>Amount</span>
            {{ $filters.currency(invoice.price) }}
        </div>
         <div class="col-xs-6 col-md-3 stat">
            <span>Status</span>
            {{ invoice.statusLabel }}
        </div>
         <div class="col-xs-6 col-md-3 stat">
            <span>Date</span>
            {{ $filters.date(invoice.date_created) }}
        </div>
    </div>
    <h2 v-if="prepayments && prepayments.items.length > 0">Service Prepayments</h2>
    <table class="table table-striped" v-if="prepayments && prepayments.items.length > 0">
        <thead>
            <tr>
                <th>Prepayment ID</th>
                <th>Prepayment Name</th>
                <th>Amount</th>
                <th>Services Used</th>
                <th>Services Allocated</th>
                <th>Completed</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="prepayment in prepayments.items">
                <td>{{ prepayment.id }}</td>
                <td>{{ prepayment.name }}</td>
                <td>{{ $filters.currency(prepayment.amount_paid) }}</td>
                <td>{{ prepayment.allocations_used }}</td>
                <td>{{ prepayment.allocated_amount }}</td>
                <td>{{ prepayment.fully_allocated }}</td>
                <td>{{ $filters.date(prepayment.date_created) }}</td>
            </tr>
        </tbody>
    </table>
    <hr v-if="prepayments && prepayments.items.length > 0">
    <h2>Service Fulfillments</h2>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Fulfillment ID</th>
                <th>Service Name</th>
                <th>Amount</th>
                <th>Prepayment</th>
                <th>Work Order ID</th>
                <th>User</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="fulfillment in fulfillments.items">
                <td>{{ fulfillment.id }}</td>
                <td>{{ fulfillment.service.name }}</td>
                <td>{{ $filters.currency(fulfillment.amount_paid) }}</td>
                <td>{{ fulfillment.prepayment ? fulfillment.prepayment.display : '-' }}</td>
                <td>{{ fulfillment.prefixed_work_order_id }}</td>
                <td>{{ fulfillment.user.email }}</td>
                <td>{{ $filters.date(fulfillment.date_created) }}</td>
            </tr>
        </tbody>
    </table>
    <paginator :total="fulfillments.totalCount" :limit="limit" :page="page" :url="paginator_url" class="pull-right"></paginator>
</div>
`;
