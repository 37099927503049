 import {html} from './NewWorkOrder.html';
import {WorkOrder} from '../../models/WorkOrder';
import WorkOrderService from '../../services/WorkOrderService';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';
 import VueComponent, {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
 import {Services} from '../../../../../../common/ts/core/services/Services';
 import RouteService from '../../../../../../common/ts/core/services/RouteService';
 import {Http} from '../../../../../../common/ts/core/services/Http';
 import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
 import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
 import NamedRouteService from '../../../../../../common/ts/core/services/NamedRouteService';

class NewWorkOrderController extends VueComponent {

    @data()
    work_orders: APIResponse<WorkOrder>;

    @data()
    limit: number = 20;

    @data()
    paginator_url: string;

    @data()
    page: number;

    @data()
    no_new_items: boolean;

    @data()
    work_order_service: WorkOrderService;

    constructor(component) {
        super(component);

        let params = Services.get<RouteService>('RouteService').current.params;

        this.page = parseInt(params.get('page')) || 1;

        this.paginator_url = Services.get<NamedRouteService>('$NamedRouteService').reverse('work-orders:new', {page: ':page'});

        this.fetchItems();

        this.work_order_service = Services.get<WorkOrderService>('WorkOrderService');
        this.work_order_service.bind('status-change', () => {
            this.work_orders = null;
            this.fetchItems();
        });
    }

    protected fetchItems() {
        let offset = (this.page - 1) * this.limit;
        this.work_orders = Services.get<typeof WorkOrder>('WorkOrder').objects.filter({status: WorkOrder.NOT_DONE, limit: this.limit, offset: offset});
    }

    @method()
    acceptNew($event) {
        const state = new ButtonProcessingState($event);
        state.process();
        this.no_new_items = false;

        Services.get<Http>('$http').request({
            url: '/processing/api/request-assignment/',
            method: 'POST',
            data: {}
        }).then(() => {
            this.fetchItems();
            state.resolved();
        }, () => {
            this.fetchItems();
            state.resolved();
            this.no_new_items = true;
        });
    }
}

export default function NewWorkOrder(): IVueComponent {
    return {
        controller: NewWorkOrderController,
        template: html,
        tag: `new-work-order`
    };
}
