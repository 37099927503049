import {html} from './UserList.html';
import {ProcessingUser} from '../../models/ProcessingUser';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import VueComponent, {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';

class UserListController extends VueComponent {

    @data()
    users: APIResponse<ProcessingUser>;

    constructor(component) {
        super(component);

        this.users = Services.get<typeof ProcessingUser>('ProcessingUser').objects.all();
    }

    @method()
    protected update(user: ProcessingUser) {
        user.save().then(() => {
        }, () => {
            user.reload();
        });
    }
}

export default function UserList(): IVueComponent {
    return {
        controller: UserListController,
        template: html,
        tag: 'user-list'
    };
}
