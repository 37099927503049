export const html:string = `
<nav v-show="totalPages > 1">
    <ul class="pagination no-print">
        <li v-if="page > 2">
            <a @click="changePage(1)" aria-label="First Page">
                <span aria-hidden="true"><i class="fa fa-angle-double-left"></i></span>
            </a>
        </li>
        <li v-if="page > 1">
            <a @click="changePage(page - 1)" aria-label="Previous Page">
                <span aria-hidden="true"><i class="fa fa-angle-left"></i></span>
            </a>
        </li>
        <li v-for="p in pages" :class="{active:p == page}"><a @click="changePage(p)">{{ p }}</a></li>
        <li v-if="page < totalPages">
            <a @click="changePage(page + 1)" aria-label="Next Page">
                <span aria-hidden="true"><i class="fa fa-angle-right"></i></span>
            </a>
        </li>
        <li v-if="page < totalPages - 1">
            <a @click="changePage(totalPages)" aria-label="Last Page">
                <span aria-hidden="true"><i class="fa fa-angle-double-right"></i></span>
            </a>
        </li>
    </ul>
</nav>
`;
