import {Collection} from '../Collection';
import {IModel} from '../IModel';
import {IFieldConfig} from './Field';
import {ForeignField} from './ForeignField';
import {ResourceURI} from '../helpers/ResourceURI';

export class ToManyField extends ForeignField {
    constructor(
        model: IModel,
        value?: any,
        config?: IFieldConfig
    ) {
        super(model, value, config);

        // Store the collection class so angular doesnt think its a new object every time
        this.reset();
    }

    private data_to_model(data) {
        if (data === null || data === undefined) {
            return null;
        }

        if (typeof data == 'string') {
            let uri = new ResourceURI(data);

            if (uri.id) {
                return this.relatedModel.objects.get({id: uri.id});
            }
        }
        if (typeof data == 'number') {
            return this.relatedModel.objects.get({id: data});
        }

        return new this.relatedModel(data);
    }

    override set value(data: any) {
        /*
         * Class that extend this field and override the setter can call this
         * to maintain the default setter functionality. The other way to do
         * this would be to use the Object.getOwnPropertyDescriptor:
         * Object.getOwnPropertyDescriptor(Foo.prototype, 'value').get.apply(this)
         */

        /*
         * If the value being set is null or is not a collection, call reset
         * to create a new collection. You can pass null here to empty a
         * list.
         */

        // Commented the line out below - Question: If we're setting the value,
        // shouldn't we replace the entire collection?
        //if(this._value == null || !(this._value instanceof this.relatedModel.collectionClass))
        this.reset();

        if (data === null || data === undefined) {
            return;
        }

        // If an array is not being added lets try to figure out what it is. This should not be passed in
        // but lets not fault if it is.
        if (!(data instanceof Array)) {
            let converted_data = this.data_to_model(data);
            if (converted_data) {
                this.getValue().push(converted_data);
            }
        }
        else {
            for (const d of data) {
                if (d === null || d === undefined) {
                    continue;
                }

                if (d instanceof this.relatedModel) {
                    this.getValue().push(d);
                }
                else {
                    let item = this.data_to_model(d);

                    if (!item) {
                        continue;
                    }

                    // If the static uri field is not passed to the related model set it manually (Bug with ts?)
                    if (!(item.constructor as any).uri && (this.model.constructor as any).uri) {
                        (item.constructor as any).uri = (this.model.constructor as any).uri;
                    }

                    this.getValue().push(item);
                }
            }
        }
        this.trigger('change');
    }

    override get value() {
        return this.getValue();
    }

    override validate() {
        super.validate();

        if (this.config['required'] === true && this.getValue() && this.getValue().length === 0)
            this._errors.push('This collection needs to have at least one item in it');

        return this._errors;
    }

    reset() {
        if ([null, undefined].indexOf(this.getValue()) > -1) {
            this.setValue(this.relatedModel ? new this.relatedModel.collectionClass() : new Collection());
        } else if (this.value) {
            this.value.length = 0;
        }
    }

    override getPostData() {
        const data = [];
        for (const m of this.value) {
            if ((m.isNew() || m.isModified() || this.config['full']) && this.config['readOnly'] !== true) {
                data.push(m.getPostData());
            } else {
                if (m.resource_uri) {
                    data.push(m.resource_uri);
                }
                else if (m.buildResourceURI()) {
                    data.push(m.buildResourceURI());
                }
            }
        }
        return data;
    }
}
