import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class ServicePrepayment extends Model {
    public static override uri: string = '/payments/api/v1/service-prepayment/:id/';
    static override objects: APIResource<ServicePrepayment>;

    @field()
    fully_allocated: boolean;

    @field()
    allocated_amount: number;

    @field()
    date_created: any;

    @field()
    amount_paid: any;

    @field()
    invoice: any;

    @field()
    prepay: any;

    @field()
    invoice_id: any;

    @field()
    allocations_used: number;

    @field()
    name: string;

    get display() {
        return `${this.name} #${this.id}`;
    }
}