export const html: string = `
<div id="view-large-customer-image-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="view-large-customer-image-modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="work_order_service.work_order">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel"><i class="fa fa-eye"></i>&nbsp;View Preview</h4>
            </div>
            <div class="modal-body clearfix">
                <img :src="work_order_service.work_order.raster_url">
            </div>
        </div>
    </div>
</div>
`;