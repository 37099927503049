import {DuplicateServiceException, UndefinedServiceException} from '../adapters/Exceptions';
import {IPromise, SimplePromise} from '../utils/SimplePromise';

export interface IServices {
    singleton(name: string, Cls: any): void;
    factory(name: string, Cls: any): void;
    registerClass(name: string, Cls: any): void;
    waitOn(name: string): IPromise<void>;
    resolve(string: string): void;
}

export namespace Services {
    const instantiated_singletons: any = {};
    const singleton_classes: any = {};
    const factory_classes: any = {};
    const class_list: any = {};
    const deferred_list: any = {};

    export function allClasses() {
        return class_list;
    }

    export function waitOn(name: string): IPromise<void> {
        if (!deferred_list[name]) {
            deferred_list[name] = SimplePromise.defer<void>();
        }

        return deferred_list[name].promise;
    }

    export function resolve(name) {
        if (!deferred_list[name]) {
            deferred_list[name] = SimplePromise.defer<void>();
        }

        deferred_list[name].resolve();
    }

    /*
        Register a singleton class.
        The class is created only once and shared between all instances when requested.
     */
    export function singleton(name: string, Cls: any) {
        validateNamespace(name);
        singleton_classes[name] = Cls;
    }

    /*
        Register a factory class.
        The class is created when requested with the injected objects included in the constructor.
     */
    export function factory(name: string, Cls: any) {
        validateNamespace(name);
        factory_classes[name] = Cls;
    }

    /*
        Register a class.
        The class will be returned on request without being constructed.
     */
    export function registerClass(name: string, Cls: any) {
        validateNamespace(name);
        class_list[name] = Cls;
    }

    /*
        Objects work the same as classes, the item is just returned
     */
    export function registerObject(name, obj: any) {
        validateNamespace(name);
        class_list[name] = obj;
    }

    /*
        Throws an exception if the name has already been registered before
     */
    function validateNamespace(name) {
        if (singleton_classes[name] || factory_classes[name] || class_list[name]) {
            let error_msg = `A duplicate service was registered with the name "${name}". Make sure that this service name is unique and not being imported more than once.`;
            console.error(error_msg);
            throw new DuplicateServiceException(error_msg);
        }

        return false;
    }

    export function get<T>(name: string): T {
        if (class_list[name]) {
            return class_list[name];
        }
        else if (factory_classes[name]) {
            const Cls = factory_classes[name];
            if (Cls.$inject) {
                return new Cls(...getList(Cls.$inject));
            }
            else {
                return new Cls();
            }
        }
        else if (singleton_classes[name]) {
            if (!instantiated_singletons[name]) {
                const Cls = singleton_classes[name];
                if (Cls.$inject) {
                    instantiated_singletons[name] = new Cls(...getList(Cls.$inject));
                }
                else {
                    instantiated_singletons[name] = new Cls();
                }
            }

            return instantiated_singletons[name];
        }

        const error_msg = `The service "${name}" has not been registered.`;
        throw new UndefinedServiceException(error_msg);
    }

    export function isRegistered(name): boolean {
        return !!class_list[name] || !!factory_classes[name] || singleton_classes[name];
    }

    export function getList(names: string[]) {
        if (!names) {
            return [];
        }

        let items = [];
        for (const name of names) {
            items.push(get(name));
        }
        return items;
    }
}