export default function DelayedSrcObserver() {
    // Does not work with elements that have position: absolute
    // See: https://stackoverflow.com/questions/65736386/intersectionobserver-and-position-absolute

    for (const el of document.querySelectorAll('[delayed-src]')) {
        let linked = false;
        let link = (entry, observer) => {
            if (linked) {
                return;
            }
            if (!entry || entry[0].isIntersecting) {
                el.setAttribute('src', el.getAttribute('delayed-src'))
                linked = true;

                if (observer) {
                    observer.unobserve(el);
                }
            }
        }

        // If IntersectionObserver is support the use it, if not lets just load the video and not worry about a polyfill
        if ('IntersectionObserver' in window) {
            let options = {
                root: null,
                rootMargin: '0px',
                threshold: 0
            }

            const observer = new IntersectionObserver(link, options);
            observer.observe(el);
        }
        else {
            link(null, null);
        }
    }
}