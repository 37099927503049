import {html} from './ImageDropdown.html';
import {GenericDropdownController} from '../GenericDropdown/GenericDropdown';
import IVueComponent from '../../adapters/IVueComponent';


export class ImageDropdownController extends GenericDropdownController {

}

export default function ImageDropdown(): IVueComponent {
    return {
        controller: ImageDropdownController,
        template: html,
        tag: 'generic-image-dropdown'
    };
}
