import Cookies from 'js-cookie';

export default class CookieManager {
    static get(key, json?) {
        return Cookies.get(key, json);
    }
    static set(key, value, attributes?) {
        return Cookies.set(key, value, attributes);
    }
    // Support for angular $cookies
    static put(key, value, attributes?) {
        CookieManager.set(key, value, attributes);
    }
    static getObject(key, json?) {
        let v = this.get(key, json);
        if (!v) {
            return v;
        }
        return JSON.parse(this.get(key, json));
    }
    static putObject(key, value, attributes?) {
        return CookieManager.setObject(key, value, attributes);
    }
    static setObject(key, value, attributes?) {
        return CookieManager.set(key, JSON.stringify(value), attributes);
    }
    static remove(key, attributes?) {
        return Cookies.remove(key, attributes);
    }
}