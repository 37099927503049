import {Invoice} from '../../models/Invoice';
import {html} from './InvoiceList.html';
import VueComponent, {data} from '../../../../../../common/ts/core/adapters/VueComponent';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import {Services} from '../../../../../../common/ts/core/services/Services';
import RouteService from '../../../../../../common/ts/core/services/RouteService';
import NamedRouteService from '../../../../../../common/ts/core/services/NamedRouteService';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';

class InvoiceListController extends VueComponent {

    @data()
    invoices: APIResponse<Invoice>;

    @data()
    limit;

    @data()
    paginator_url;

    @data()
    page;

    constructor(component) {
        super(component);

        let params = Services.get<RouteService>('RouteService').current.params;

        this.page = parseInt(params.get('page')) || 1;
        this.limit = 20;
        this.paginator_url = Services.get<NamedRouteService>('$NamedRouteService').reverse('payments:invoice-list', {page: ':page'});

        let offset = (this.page - 1) * this.limit;

        this.invoices = Services.get<typeof Invoice>('Invoice').objects.filter({
            limit: this.limit, offset: offset
        });
    }
}

export default function InvoiceList(): IVueComponent {
    return {
        controller: InvoiceListController,
        template: html,
        tag: `invoice-list`
    };
}
