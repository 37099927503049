import IVueDirective from '../../adapters/IVueDirective';
import tippy from 'tippy.js';

import Company from '../../services/Company';
import {Services} from '../../services/Services';

export default function Tooltip(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                const company = Services.get<Company>('Company')
                const content = el.attributes['tooltip-content'].value
                let position = null;

                if(el.attributes['tooltip-position']) {
                    position = el.attributes['tooltip-position'].value
                }
                else {
                    position = 'top'
                }

                tippy(el, {
                    content: content,
                    placement: position,
                    theme: company.site_theme,
                    animation: 'perspective'
                })
            },
        },
        tag: 'tooltip'
    }
}