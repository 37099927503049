import {Services} from '../../../common/ts/core/services/Services';
import Company from '../../../common/ts/core/services/Company';
import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import {Integration} from '../../../common/ts/core/utils/Integration';
import VueApp from '../../../common/ts/core/adapters/VueApp';
import registerCoreComponents from '../../../common/ts/core/components/init';
import registerLoginComponents from '../../../common/ts/apps/login/components/init';
import DelayedSrcObserver from '../../../common/ts/core/utils/DelayedSrcObserver';
import registerUserModels from '../../../common/ts/apps/userprofile/models/init';
import registerCoreServices from '../../../common/ts/core/services/init';
import registerUserServices from '../../../common/ts/apps/userprofile/services/init';
import registerAccountServices from '../../ts/apps/accounts/services/init';
import registerPaymentServices from '../../ts/apps/payment/services/init';
import registerWorkOrderServices from '../../ts/apps/work_order/services/init';
import registerAccountComponents from '../../ts/apps/accounts/components/init';
import registerPaymentComponents from '../../ts/apps/payment/components/init';
import registerWorkOrderComponents from '../../ts/apps/work_order/components/init';
import registerWorkOrderModels from '../../ts/apps/work_order/models/init';
import registerPaymentModels from '../../ts/apps/payment/models/init';
import registerAccountModels from '../../ts/apps/accounts/models/init';
import DashboardNav from '../../../dashboard/ts/core/apps/DashboardNav';
import registerObject = Services.registerObject;
import {ProcessingRouteList} from '../../ts/core/services/ProcessingRouteList';
import registerProcessingCoreComponents from '../../ts/core/components/init';


class CarStickersCoreIntegration extends Integration {
    override setupSentry() {
        let environment = 'production';
        if (window.location.origin.indexOf('192.168') != -1 || window.location.origin.indexOf('localhost') != -1) {
            environment = 'development';
        }
        else if (window.location.origin.indexOf('staging') != -1) {
            environment = 'staging';
        }

        // Dont log with development environment
        if (environment != 'development') {
            Sentry.init({
                dsn: "https://e85f8c30a66d4e0290546d13358a6201@o116203.ingest.sentry.io/5597605",

                // To set your release version
                release: "processing-frontend@2.0.0",
                integrations: [new Integrations.BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
                // Until we upgrade our account and want to use performance sampling this is being disabled
                tracesSampleRate: 0,
                environment: environment,
            });
        }
    }

    override configureServices() {
        Services.get<Company>('Company').setup(
            'Car Stickers Inc.',
            '844-647-2730',
            'sales@carstickers.com',
            '2146 NE 4th Street \n ' +
                    'Suite 100 \n ' +
                    'Bend, OR 97701',
            'https://www.carstickers.com/static/img/logo-large.png',
            'CarStickers',
            'carstickers.com',
            'https://www.facebook.com/carstickerscom/reviews/',
            'https://www.yelp.com/writeareview/biz/14GjYO0wVEoi7TjfivDnww',
            'https://www.google.com/search?q=Car+Stickers+Inc.#lrd=0x54b8c86dd7cdd411:0xacde6a97ea678204,3',
            'carstickers'
        );

        Services.resolve('loaded-core');
    }

    setupApps(): void {
        const login_app = new VueApp();
        registerCoreComponents(login_app);
        registerLoginComponents(login_app);
        login_app.mount('#login-app');

        let nav = new DashboardNav();
        nav.mount('#left-nav');

        const global_app = new VueApp();
        registerProcessingCoreComponents(global_app)
        registerCoreComponents(global_app);
        registerAccountComponents(global_app);
        registerPaymentComponents(global_app);
        registerWorkOrderComponents(global_app);
        global_app.mount('#vue-app');
    }

    override setupGlobalModules(): void {
        super.setupGlobalModules();
        DelayedSrcObserver();
    }

    setupModels(): void {
        // Models
        registerUserModels();
        registerAccountModels();
        registerWorkOrderModels();
        registerPaymentModels()
    }

    setupServices(): void {
        window['Services'] = Services;

        registerObject('RouteList', ProcessingRouteList);

        // Services
        registerCoreServices(Services);
        registerUserServices(Services);
        registerAccountServices(Services);
        registerPaymentServices(Services);
        registerWorkOrderServices(Services);
    }
}
const core = new CarStickersCoreIntegration();
