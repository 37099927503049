import APIResponse from '../../../core/models/APIResponse';
import {MetricOptions, User} from '../models/User';
import {METRIC_OPTIONS_ITEMS} from '../models/User';
import {Http} from '../../../core/services/Http';
import {EventDispatcher} from 'simple-ts-event-dispatcher';

export default class UserProfileService extends EventDispatcher {
    static $inject = [
        '$http',
        'User',
    ];

    public user: User;
    private users: APIResponse<User>;
    public login_redirect;

    constructor(
        private $http: Http,
        private UserModel: typeof User,
    ) {
        super();
        let user_element = document.getElementById("user-data")
        if (user_element && user_element.textContent) {
            this.users = this.UserModel.objects.from(
                JSON.parse(decodeURIComponent(escape(atob(user_element.textContent.replace(/(\r\n\t|\n|\r\t|\s)/gm, '')))))
            );

            if (this.users.items.length > 0) {
                this.user = this.users.items[0];
            }
        }
    }

    loggedIn(): boolean {
        return !!this.user
    }

    fetchUser() {
        return this.user
    }

    updateSubscriptionPreferences(subscribe: boolean) {
        this.user.is_marketing_eligible = subscribe;
        this.$http.request({
            url: '/profile/api/subscription-update/',
            method: 'POST',
            data: {
                marketing: subscribe
            }
        }).then((response) => {
            this.user.reload();
        }, (response) => {
            this.user.reload();

            return response;
        });
    }

    convertSizeFromInches(inches) {
        if (this.user) {
            return this.user.convertSizeFromInches(inches);
        }
        else {
            return inches;
        }
    }

    convertSizeToInches(inches) {
        if (this.user) {
            return this.user.convertSizeToInches(inches);
        }
        else {
            return inches;
        }
    }

    get is_unit_default() {
        if (this.user) {
            return this.user.is_unit_default;
        }

        return true;
    }

    get size_label() {
        if (this.user) {
            return this.user.size_label;
        }
        return 'inches'
    }

    get size_notation() {
        if (this.user) {
            return this.user.size_notation;
        }

        return '"'
    }
}
