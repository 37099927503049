export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>Add User</h1>
    </div>
</div>

<div class="main-container">
    <div class="col-xs-6 col-full-left">
        <div class="form-group">
            <label>Email:</label>
            <error-list :errors="errors" field="email"></error-list>
            <input class="form-control" v-model="user.email" :disabled="!!user.id">
        </div>
        
        <div class="form-group">
            <label>Name:</label>
            <input class="form-control" v-model="user.name">
        </div>
        
        <div class="form-group">
            <label>Title:</label>
            <input class="form-control" v-model="user.title">
        </div>
        
        <div class="form-group">
            <label>Phone:</label>
            <input class="form-control" v-model="user.phone">
        </div>
        
        <div class="form-group">
            <label>Cell Phone:</label>
            <input class="form-control" v-model="user.cell_number">
        </div>
        
        <div class="form-group">
            <label>Note:</label>
            <textarea class="form-control" v-model="user.note"></textarea>
        </div>
    </div>
    
    <div class="col-xs-6 col-fill-right">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Permissions</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Active</td>
                    <td>
                        <input type="checkbox" v-model="user.permission_active">
                    </td>
                </tr>
                <tr>
                    <td>Processing</td>
                    <td>
                        <input type="checkbox" v-model="user.permission_processing">
                    </td>
                </tr>
                <tr>
                    <td>Invoices</td>
                    <td>
                        <input type="checkbox" v-model="user.permission_company">  
                    </td>
                </tr>
                <tr>
                    <td>Manager</td>
                    <td>
                        <input type="checkbox" v-model="user.permission_invoice">  
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-xs-12 col-full">
        <div class="col-xs-6 pull-right col-full">
            <error-list :errors="errors" field="__all__"></error-list>
            <success-list :messages="success"></success-list>
            <button class="btn btn-primary pull-right" @click="save($event)">{{ user.id ? 'Save' : 'Add User' }}</button>
            <a class="btn btn-warning pull-right" href="/profile/users/">Back to Users</a>
        </div>
    </div>
</div>
`;
