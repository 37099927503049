import {Model} from '../Model';
import {ForeignField} from './ForeignField';
import {ResourceURI} from '../helpers/ResourceURI';

export class ForeignKeyField extends ForeignField {
    override set value(data: any) {
        if (data == null) {
            this.setValue(null);
            return;
        }

        // If the value is getting set for the first time lets parse it
        if (this._value === undefined) {
            data = this.initialValueSetup(data);
        }

        if (data instanceof this.relatedModel) {
            this.setValue(data);
        }
        else if (data != null && data != '') {
            let data_type = typeof data
            if (data_type == 'string' || data_type == 'number') {
                let uri = null;
                if (typeof data === 'string') {
                    uri = new ResourceURI(data);
                    if (uri.id) {
                        this.setValue(this.relatedModel.objects.get({id: uri.id}));
                    }
                }
                else if (typeof data === 'number') {
                    this.setValue(this.relatedModel.objects.get({id: data}));
                }
            }
            else {
                this.setValue(new this.relatedModel(data));
            }
        }
        else {
            this.setValue(data);
        }
    }

    protected override initialValueSetup(data): any {
        if (!data) {
            return null;
        }

        // If the model specifies that a foreign key object is set but a id or string was given
        // lets load the data from the server
        let data_type = typeof data
        if (data_type == 'string' || data_type == 'number') {
            let uri = null;
            if (typeof data === 'string') {
                uri = new ResourceURI(data);
                if (uri.id) {
                    return this.relatedModel.objects.get({id: uri.id})
                }
            }
            else if (typeof data === 'number') {
                return this.relatedModel.objects.get({id: data})
            }
        }

        return data;
    }

    override get value() {
        return this.getValue();
    }

    override getPostData() {
        if (this.value instanceof Model) {
            if (this.config['readOnly'] === true || (!this.value.isModified() && typeof(this.value.resource_uri) === 'string')) {
                return this.value.resource_uri ? this.value.resource_uri : this.value.buildResourceURI();
            } else if (!this.value.isNull()) {
                return this.value.getPostData();
            }
        }

        return null;
    }
}
