import {EventDispatcher} from 'simple-ts-event-dispatcher';
import axios, {AxiosResponse} from 'axios';
import {Model} from './Model';
import {Collection} from './Collection';
import {uuid4} from '../utils/uuid';
import {IDeferred, IPromise, SimplePromise} from '../utils/SimplePromise';
import ModelSyncService from '../services/ModelSyncService';
import {Services} from '../services/Services';
import {Http} from '../services/Http';

export default class APIResponse<T extends Model> extends EventDispatcher {
    public limit: number;
    public previous: string;
    public next: string;
    public offset: number;
    public totalCount: number;
    public extra: any;
    public request_uuid: string;

    public model: typeof Model;
    public request: IPromise<AxiosResponse>;
    public response: AxiosResponse;

    public params: Object;

    public itemsReady: boolean = false;
    private _deferred: IDeferred<Collection<T>>;
    private deferredOverride: IDeferred<Collection<T>>;
    private _items: Collection<T>;
    private _uuid: string;
    private addition_queue: number[];
    private syncAdditionScope: any;

    constructor(request: IPromise<AxiosResponse>, model: typeof Model, params: any, data?: any) {
        super();
        this.addition_queue = [];
        this.request = request;
        this.model = model;
        this.params = params;
        this.items = new Collection<T>();

        if (this.request && !data) {
            this.request.then(this.success.bind(this), this.error.bind(this));
        }

        this._deferred = SimplePromise.defer<Collection<T>>();

        if (data) {
            this.setPostData(data);
        }
    }

    reload(): IPromise<Collection<T>> {
        if (!this.response) {
            return;
        }

        if (this.response.config.method != 'get') {
            console.warn('Only GET requests can be reloaded.');
            return;
        }

        this.request = Services.get<Http>('$http').get(this.response.config.url, this.response.config);
        this.request.then(this.success.bind(this), this.error.bind(this));

        this.response = null;
        this._deferred = SimplePromise.defer<Collection<T>>();

        return this.$promise;
    }

    subset(filter) {
        let new_response = new APIResponse(null, this.model, null, null);

        let deferred = SimplePromise.defer<Collection<any>>();
        new_response.overrideDeferrer(deferred);

        this.$promise.then(() => {
            new_response.items = this.items.filter(filter);
            deferred.resolve(new_response.items);
        })

        return new_response;
    }


    get items(): Collection<T> {
        return this._items;
    }

    get collectionClass() {
        if (this.model.collectionClass) {
            return this.model.collectionClass;
        }
        return Collection
    }

    set items(items: Collection<T> | T[]) {
        this._items = new this.collectionClass();

        if (!items) return;
        for (const item of items) {
            let mdl = new this.model(item) as T;
            mdl.bind('sync', () => {
                this.trigger('sync');
            })
            this._items.push(mdl);
        }
    }

    getPostData() {
        const items_data = [];

        for (const item of this.items) {
            items_data.push(item.getPostData());
        }

        return {
            items: items_data,
            limit: this.limit,
            previous: this.previous,
            next: this.next,
            offset: this.offset,
            totalCount: this.totalCount
        };
    }

    setPostData(data) {
        this._items = new this.collectionClass();

        this.items = data.items || data.objects;

        this.limit = data.limit || data.meta && data.meta.limit;
        this.previous = data.previous || data.meta && data.meta.previous;
        this.next = data.next || data.meta && data.meta.next;
        this.offset = data.offset || data.meta && data.meta.offset;
        this.totalCount = data.totalCount || data.meta && data.meta.totalCount;
        this.extra = data.extra ? data.extra : {};
        this.request_uuid = data.meta ? data.meta.request_uuid : null;

        this.itemsReady = true;
        this.trigger('itemsReady');
        this._deferred.resolve(this.items);
    }

    public reset(): void {
        this.limit = 0;
        this.previous = '';
        this.next = '';
        this.offset = 0;
        this.totalCount = 0;
        this.itemsReady = false;
        this.extra = {};
        this.request_uuid = null;
        this.items = new Collection<T>();
    }

    private success(response: any): void {
        this.response = response;

        let data = response.data;

        this.reset();

        if(response.status == 204) {
            this.reset();
            this._deferred.resolve(null)
            return;
        }

        if (!data) {
            return;
        }

        if (data.objects) {
            this.items = data.objects;

            // Meta Information
            this.limit = data.meta.limit;
            this.previous = data.meta.previous;
            this.next = data.meta.next;
            this.offset = data.meta.offset;
            this.totalCount = data.meta.total_count;
            this.extra = data.meta.extra ? data.meta.extra : {};
            this.request_uuid = data.meta.request_uuid;
        }
        else if (data.resource_uri) {
            this.items = [data] as any;
        }
        else {
            return;
        }

        if (this.deferredOverride) {
            this.deferredOverride.promise.then(() => {
                this.itemsReady = true;
                this.trigger('itemsReady');
            });
        }
        else {
            this.itemsReady = true;
            this.trigger('itemsReady');
        }

        this._deferred.resolve(this.items);
    }

    public overrideDeferrer(deferred: IDeferred<Collection<T>>) {
        this.deferredOverride = deferred;
    }

    get $promise(): IPromise<Collection<T>> {
        if (this.deferredOverride) {
            return this.deferredOverride.promise;
        }

        return this._deferred.promise;
    }

    get deferred(): IDeferred<Collection<T>> {
        return this._deferred;
    }

    get $resolved(): any {
        return this.itemsReady;
    }

    private error(response: any): void {
        this.reset();
        this._deferred.reject(response);
    }

    // This can cause a memory leak if not properly cleaned up. Just setting it to null will not free the memory or
    // deregister the local and server listeners unless a scope is provided and it is destroyed. Call disableSync
    // before setting null
    public enableSync($scope, mode?) {
        this.$promise.then(() => {
            for (const item of this.items) {
                item.enableSync($scope, mode);
            }
        })
    }

    // This can cause a memory leak if not properly cleaned up. Just setting it to null will not free the memory or
    // deregister the local and server listeners unless a scope is provided and it is destroyed. Call disableSync
    // before setting null
    public enableAdditionSync($scope?) {
        this.syncAdditionScope = $scope;
        this.$promise.then(() => {
            Services.get<ModelSyncService>('ModelSyncService').registerQuery(this, $scope);

            for (const item of this.items) {
                this.bindItemChangeRemoval(item);
                item.enableSync($scope)
            }
        });
    }

    // Cleans up server and local listeners and instances to free memory
    public disableSync(all?) {
        Services.get<ModelSyncService>('ModelSyncService').deregisterQuery(this);

        if (all) {
            for (const item of this.items) {
                item.disableSync();
            }
        }
    }

    get uuid() {
        if (!this._uuid) {
            this._uuid = uuid4();
        }
        return this._uuid;
    }

    onServerAddition(id: number) {
        if (!id) {
            console.warn('Model sync addition did not provide a id, stopping data sync.');
            return;
        }

        // If it already exists do nothing. The normal sync will update it.
        for (const item of this.items) {
            if (item.id === id) {
                return;
            }
        }
        // If we are already fetching them item but waiting on a response dont add it again
        if (this.addition_queue.indexOf(id) != -1) {
            return;
        }
        this.addition_queue.push(id);

        let model = this.model.objects.get({id: id});
        model.$promise.then(() => {
            this.bindItemChangeRemoval(model);
            this.items.push(model as any);
            model.enableSync(this.syncAdditionScope);
            this.trigger('on-sync-addition', model);

            if (this.addition_queue.indexOf(id) != -1) {
                this.addition_queue.splice(this.addition_queue.indexOf(id), 1);
            }

            this.trigger('sync');
        });
    }

    private bindItemChangeRemoval(item) {
        item.bind('sync-reload', () => {
            for (const filter_key in this.params) {
                // We should probably implement a subset of the available queries or send a request to the server to
                // verify that its still valid for the queryset. However for now we will only match basic value checks.
                if (item[filter_key] == undefined || filter_key.indexOf('__') != -1) {
                    continue
                }

                // We are unable to build a queryset for null in javascript so we need to accept 'None' instead.
                // This will conflict for a string search for that value so we may need to make it so this wont
                // be replaced for __eq so we can do foo__eq='None' however we currently dont have a use case for this.
                let filter_value = this.params[filter_key];
                if (filter_value == 'None') {
                    filter_value = null;
                }

                let is_same = item[filter_key] == filter_value;
                if (!is_same && filter_value != null && typeof item[filter_key]) {
                    if (typeof item[filter_key] == 'object') {
                        if (typeof filter_value == 'number' && item[filter_key].id) {
                            is_same = item[filter_key].id == filter_value;
                        }
                        else if (typeof filter_value == 'string' && item[filter_value].resource_uri) {
                            is_same = item[filter_key].resource_uri == filter_value;
                        }
                        else if (typeof filter_value == 'string' && item[filter_value].buildResourceURI && item[filter_value].buildResourceURI()) {
                            is_same = item[filter_value].buildResourceURI() == filter_value;
                        }
                    }
                }

                if (!is_same) {
                    if (this.items.indexOf(item) != -1) {
                        this.items.splice(this.items.indexOf(item), 1);
                        item.disableSync();
                        item.trigger('on-sync-removal');
                    }
                }
            }

            this.trigger('sync');
        });
    }
}
