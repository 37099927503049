import {html} from './AccountLoginModal.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {ModalController} from '../../../../core/adapters/ModalComponent';
import {Services} from '../../../../core/services/Services';
import UserProfileService from '../../../userprofile/services/UserProfileService';

class AccountLoginModalController extends ModalController {
    constructor(component){
        super(component, 'loginModal');
    }

    protected override onClose() {
        Services.get<UserProfileService>('UserProfileService').trigger('reset-login-modal');
    }
}

export default function AccountLoginModal(): IVueComponent{
    return {
        template: html,
        controller: AccountLoginModalController,
        tag: 'account-login-modal'
    }
}
