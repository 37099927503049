import {html} from './Home.html';
import VueComponent, {data} from '../../../../../../common/ts/core/adapters/VueComponent';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import {InstructionVideo} from '../../models/InstructionVideo';
import {InstructionDownload} from '../../models/InstructionDownload';
import {WorkOrder} from '../../models/WorkOrder';
import {Services} from '../../../../../../common/ts/core/services/Services';
import {Http} from '../../../../../../common/ts/core/services/Http';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import ProcessingUserService from '../../../accounts/services/ProcessingUserService';


class HomeController extends VueComponent {
    @data()
    videos: APIResponse<InstructionVideo>;

    @data()
    downloads: APIResponse<InstructionDownload>;

    @data()
    work_orders: APIResponse<WorkOrder>;

    @data()
    user_service: ProcessingUserService;

    @data()
    new_count: number;

    constructor(component) {
        super(component);

        this.videos = Services.get<typeof InstructionVideo>('InstructionVideo').objects.all();
        this.downloads = Services.get<typeof InstructionDownload>('InstructionDownload').objects.all();
        this.work_orders = Services.get<typeof WorkOrder>('WorkOrder').objects.filter({status: WorkOrder.NOT_DONE});
        this.user_service = Services.get<ProcessingUserService>('ProcessingUserService');
        this.new_count = -1;

        Services.get<Http>('$http').request({
            url: '/processing/api/new-work-order-count/',
            method: 'GET',
            params: {},
        }).then((response: any) => {
            this.new_count = response.data.count;
            this.$forceUpdate();
        });
    }
}

export default function Home(): IVueComponent {
    return {
        controller: HomeController,
        template: html,
        tag: 'processing-home'
    };
}
