import {html} from './UserAdd.html';
import {ProcessingUser} from '../../models/ProcessingUser';
import MessageList from '../../../../../../common/ts/core/utils/MessageList';
import VueComponent, {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import {Http} from '../../../../../../common/ts/core/services/Http';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';

class UserDetailController extends VueComponent {

    @data()
    user: ProcessingUser;

    @data()
    success: string[];

    @data()
    errors: MessageList;

    constructor(component) {
        super(component);

        this.user = new (Services.get<typeof ProcessingUser>('ProcessingUser'))();
        this.errors = new MessageList();
        this.success = [];
    }

    @method()
    back($event) {
        if ($event) {
            $event.preventDefault();
        }

        window.history.back();
    }

    @method()
    save($event) {
        let state = new ButtonProcessingState($event);
        state.process();

        this.errors = new MessageList();
        this.success = [];

        if (!this.user.id) {
            Services.get<Http>('$http').request({
                url: '/users/api/add-user/',
                method: 'POST',
                data: {
                    email: this.user.email,
                    name: this.user.name,
                    phone: this.user.phone,
                    cell_number: this.user.cell_number,
                    title: this.user.title,
                    note: this.user.note,
                    permission_active: this.user.permission_active,
                    permission_company: this.user.permission_company,
                    permission_invoice: this.user.permission_company,
                    permission_processing: this.user.permission_processing

                }
            }).then((response) => {
                this.success.push(response.data.message);
                this.user = Services.get<typeof ProcessingUser>('ProcessingUser').objects.get({id: response.data.id});

                state.resolved();
            }, (response) => {
                this.errors = response.data.errors;
                state.resolved();
            });
        }
        else {
            this.user.save();
        }
    }
}

export default function UserAdd(): IVueComponent {
    'use strict';

    return {
        controller: UserDetailController,
        template: html,
        tag: 'add-user'
    };
}
