import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {Http} from '../../../core/services/Http';
import {Services} from '../../../core/services/Services';
import {clamp} from '../../../core/utils/utils';
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {UserAddress} from './UserAddress';

export enum MetricOptions {
    METRIC = 1,
    IMPERIAL = 2
}

export const METRIC_OPTIONS_ITEMS = [
    {id: MetricOptions.METRIC, name: 'Metric'},
    {id: MetricOptions.IMPERIAL, name: 'US Imperial'}
];

export class User extends Model {
    static override uri: string = '/profile/api/user/user/:id/';
    static override objects: APIResource<User>;

    @field()
    date_joined: string;

    @field(ForeignKeyURIField, {
        model: 'UserAddress'
    })
    default_billing_address: TForeignKeyURI<UserAddress>;

    @field(ForeignKeyURIField, {
        model: 'UserAddress'
    })
    default_shipping_address: TForeignKeyURI<UserAddress>;

    @field()
    email: string;

    @field()
    cell_number: string;

    @field()
    in_store_credit: number;

    @field()
    is_active: boolean;

    @field()
    last_login: string;

    @field()
    is_marketing_eligible: boolean;

    @field()
    show_dash: boolean;

    @field()
    show_admin: boolean;

    @field()
    metric_option: any;

    updateCellNumber() {
        return Services.get<Http>('$http').request({
            url: '/profile/api/cell-number-update/',
            method: 'POST',
            data: {
                customer_id: this.id,
                cell_number: this.cell_number
            }
        });
    }

    convertSizeFromInches(inches) {
        if(this.metric_option == MetricOptions.METRIC) {
            return inches * 2.54;
        }
        else {
            return inches;
        }
    }

    convertSizeToInches(inches) {
        if(this.metric_option == MetricOptions.METRIC) {
            return inches / 2.54;
        }
        else {
            return inches;
        }
    }

    get is_unit_default() {
        return !this.metric_option || this.metric_option == MetricOptions.IMPERIAL;
    }

    get size_label() {
        if(this.metric_option == MetricOptions.METRIC) {
            return 'centimeters'
        }
        else {
            return 'inches'
        }
    }

    get size_notation() {
        if(this.metric_option == MetricOptions.METRIC) {
            return 'cm'
        }
        else {
            return '"'
        }
    }
}
