import {field} from '../../../../../common/ts/core/models/fields/Field';
import {Model} from '../../../../../common/ts/core/models/Model';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class ProvidedService extends Model {
    public static override readonly uri: string = '/work-order/api/v1/service/:id/';
    static override objects: APIResource<ProvidedService>;

    @field()
    name: string;

    @field()
    price: string;
}
