import InjectedService from './InjectedService';

/*  Company

    A simple service to store and load company information based on the app context.

    This should be setup when the app loads so that the information can be used even during api server errors
 */
export default class Company extends InjectedService {
    public name: string;
    public phone: string;
    public email: string;
    public address: string;
    public logo: string;
    public short_name: string;
    public short_url: string;
    public facebook_url: string;
    public yelp_url: string;
    public google_url: string;
    public pintrist_tracking_id: string;
    public site_theme: string;
    constructor() {
        super();
    }

    setup(name: string, phone: string, email: string, address: string,
          logo: string, short_name: string, short_url: string,
          facebook_url: string, yelp_url: string, google_url: string, site_theme: string) {
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.address = address;
        this.logo = logo;
        this.short_name = short_name;
        this.short_url = short_url;
        this.facebook_url = facebook_url;
        this.yelp_url = yelp_url;
        this.google_url = google_url;
        this.site_theme = site_theme;
    }
}
