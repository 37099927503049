export const html: string = `
<nav v-show="pages && pages.length > 1 && isValid()">
    <ul class="pagination no-print">
        <li v-if="showPrevious && first.page != previous.page">
            <a :href="first.url" aria-label="First Page">
                <span aria-hidden="true"><i class="fa fa-angle-double-left"></i></span>
            </a>
        </li>
        <li v-if="showPrevious">
            <a :href="previous.url" aria-label="Previous Page">
                <span aria-hidden="true"><i class="fa fa-angle-left"></i></span>
            </a>
        </li>
        <li v-for="p in pages" :class="{active: page == p.page}" ><a :href="p.url">{{ p.page }}</a></li>
        <li v-if="showNext">
            <a :href="next.url" aria-label="Next Page">
                <span aria-hidden="true"><i class="fa fa-angle-right"></i></span>
            </a>
        </li>
        <li v-if="showNext && next.page != last.page">
            <a :href="last.url" aria-label="Last Page">
                <span aria-hidden="true"><i class="fa fa-angle-double-right"></i></span>
            </a>
        </li>
        <li v-if="limits" v-for="l in limits">
            <a @click="limit = l">{{ limit }} PER PAGE</a>
        </li>
    </ul>
</nav>
`;
