import {copyToClipboard} from '../utils/utils';
import IVueDirective from '../adapters/IVueDirective';

export default function CopyToClipboard(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                const elementToCopy = <HTMLInputElement>document.getElementById(el.$attrs['selector'])
                el.on('click', () => {
                    switch (elementToCopy.tagName) {
                        case 'INPUT': {
                            copyToClipboard(elementToCopy.value);
                            break;
                        }

                        case 'TEXTAREA': {
                            elementToCopy.select();
                            copyToClipboard(elementToCopy.value);
                            break
                        }

                        default: {
                            copyToClipboard(elementToCopy.value);
                            break;
                        }
                    }})
            },
        },
        tag: 'copy-to-clipboard'
    }
}