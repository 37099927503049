/*
    A basic polyfill for when cookies are not enabled but we don't want to throw exceptions when setting one
 */
export default class BasicStorageManager {
    private data: Map<string, any>;

    constructor() {
        this.data = new Map<string, any>();
    }

    get(key, json?) {
        return this.data.get(key);
    }

    set(key, value, attributes?) {
        return this.data.set(key, value);
    }

    // Support for angular $cookies
    put(key, value, attributes?) {
        this.set(key, value, attributes);
    }

    getObject(key, json?) {
        return this.data.get(key)
    }

    putObject(key, value, attributes?) {
        return this.data.set(key, value);
    }

    setObject(key, value, attributes?) {
        return this.data.set(key, value);
    }

    remove(key, attributes?) {
        this.data.delete(key);
    }
}