import {html} from './UploadWorkOrder.html';
import WorkOrderService from '../../services/WorkOrderService';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';
import {ModalController} from '../../../../../../common/ts/core/adapters/ModalComponent';
import {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import {eventToFiles, processRequestError} from '../../../../../../common/ts/core/utils/utils';
import {Http} from '../../../../../../common/ts/core/services/Http';
import {Services} from '../../../../../../common/ts/core/services/Services';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import MessageList from '../../../../../../common/ts/core/utils/MessageList';
import {WorkOrderFile} from '../../models/WorkOrderFile';

export class UploadWorkOrderController extends ModalController {

    @data()
    name: string;

    @data()
    sku: string;

    @data()
    file: File;

    @data()
    customer_file: File;

    @data()
    note: string;

    @data()
    success: boolean;

    @data()
    show_verification: boolean;

    @data()
    verification_raster: string;

    @data()
    verification_customer_raster: string;

    @data()
    verification_id: string;

    @data()
    processing: boolean;

    @data()
    errors: MessageList = new MessageList();

    constructor(component) {
        super(component, 'upload-work-order');
    }

    @method()
    setupFile($event) {
        this.file = eventToFiles($event)[0];
    }

    @method()
    setupCustomerFile($event) {
        this.customer_file = eventToFiles($event)[0];
    }

    @method()
    cancel() {
        let file = Services.get<WorkOrderService>('WorkOrderService').work_order_file;
        if (file) {
            file.status = WorkOrderFile.REMOVED;
            file.save();
        }
    }

    @method()
    save($event) {
        this.errors.reset();

        const state = new ButtonProcessingState($event);
        state.process();

        let data = new FormData();

        data.set('work_order', `${Services.get<WorkOrderService>('WorkOrderService').work_order.id}`);
        data.set('note', this.note);

        if (this.file) {
            data.set('file', this.file);
        }

        if (this.customer_file) {
            data.set('customer_file', this.customer_file);
        }

        Services.get<Http>('$http').request({
            url: '/processing/api/upload/',
            method: 'POST',
            data: data
        }).then((response) => {
            this.success = true;
            this.customer_file = null;
            this.file = null;
            this.show_verification = true;
            this.verification_raster = response.data.raster;
            this.verification_customer_raster = response.data.customer_raster;
            this.verification_id = response.data.work_order_file;
            Services.get<WorkOrderService>('WorkOrderService').work_order.reload_files();
            state.resolved();
        }, (error) => {
            Services.get<WorkOrderService>('WorkOrderService').work_order.reload();
            this.reset();

            this.errors = processRequestError(error);
            state.resolved();
        });
    }

    @method()
    acceptUpload($event) {
        this.errors.reset();

        const state = new ButtonProcessingState($event);
        state.process();

        Services.get<Http>('$http').request({
            url: '/processing/api/approve-work-order-file/',
            method: 'POST',
            data: {
                work_order_file: this.verification_id
            }
        }).then((response) => {
            this.success = false;
            Services.get<WorkOrderService>('WorkOrderService').work_order.reload();
            Services.get<WorkOrderService>('WorkOrderService').trigger('status-change');
            this.reset();
            this.close();
            state.resolved();
        }, (error) => {
            state.resolved();

            this.errors = processRequestError(error);
        });
    }

    override reset() {
        this.name = '';
        this.sku = '';
        this.file = null;
        this.customer_file = null;
        this.success = false;
        this.note = '';
        this.verification_id = null;
        this.errors = new MessageList();

        super.reset();
    }

    override onOpen() {
        super.onOpen();
        this.reset();
    }
}

export default function UploadWorkOrder(): IVueComponent {
    return {
        controller: UploadWorkOrderController,
        template: html,
        tag: 'upload-work-order'
    };
}
