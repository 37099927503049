import {html} from './ViewWorkOrderCustomerFile.html';
import WorkOrderService from '../../services/WorkOrderService';
import VueComponent, {data, prop} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';

class ViewWorkOrderFilesController extends VueComponent {

    @data()
    work_order_service: WorkOrderService;

    constructor(component) {
        super(component);
        this.work_order_service = Services.get<WorkOrderService>('WorkOrderService');
    }
}

export default function ViewWorkOrderCustomerFile(): IVueComponent {
    return {
        controller: ViewWorkOrderFilesController,
        template: html,
        tag: 'view-work-order-customer-image'
    };
}