import {Model} from '../../../core/models/Model';
import {Field, field} from '../../../core/models/fields/Field';
import MessageList from '../../../core/utils/MessageList';
import {WholesaleMembership} from './WholesaleMemebership';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {CustomOption} from '../../svgcustom/models/CustomOption';
import {ForeignKeyURIField} from '../../../core/models/fields/ForeignKeyURIField';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export enum BUSINESS_SIZE_CHOICES {
    SINGLE_PERSON = 0,
    SMALL_BUSINESS = 1,
    MEDIUM_BUSINESS = 2,
    LARGE_BUSINESS = 3
}

export const BUSINESS_SIZE_ITEMS = [
    {id: BUSINESS_SIZE_CHOICES.SINGLE_PERSON, name: '1'},
    {id: BUSINESS_SIZE_CHOICES.SMALL_BUSINESS, name: '2 to 10'},
    {id: BUSINESS_SIZE_CHOICES.MEDIUM_BUSINESS, name: '11 to 50'},
    {id: BUSINESS_SIZE_CHOICES.LARGE_BUSINESS, name: '50+'},
];

export enum BUSINESS_TYPE_CHOICES {
    BUSINESS_ADVERTISING = 0,
    BUSINESS_PROMOTIONAL = 1,
    BUSINESS_APPAREL = 2,
    BUSINESS_SIGNS = 3,
    BUSINESS_BROKER = 4,
    BUSINESS_PRINTER = 4,
    BUSINESS_OTHER = 5,
    BUSINESS_GOV_SCHOOL = 6,
    BUSINESS_RETAIL = 7,
}

export const BUSINESS_TYPE_CHOICES_ITEMS = [
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_ADVERTISING, name:'Advertising / Marketing'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_PROMOTIONAL, name:'Promotional Product Distributor'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_APPAREL, name:'Apparel Embroiderer / Screen Printer'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_SIGNS, name:'Sign Manufacture'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_BROKER, name:'Print Broker'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_PRINTER, name:'Commercial Printer'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_GOV_SCHOOL, name:'Government / School'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_RETAIL, name:'Retail'},
    {id: BUSINESS_TYPE_CHOICES.BUSINESS_OTHER, name:'Other'}
];

export enum REFERRAL_CHOICES {
    REFERRAL_ADVERTISING = 0,
    REFERRAL_TRADE_ASSOCIATION = 1,
    REFERRAL_EMAIL = 2,
    REFERRAL_DIRECT_MAIL = 3,
    REFERRAL_TRADE_SHOW = 4,
    REFERRAL_STANDARD = 5,
    REFERRAL_OTHER = 6,
    REFERRAL_GSA = 7
}

export const REFERRAL_CHOICES_ITEMS = [
    {id: REFERRAL_CHOICES.REFERRAL_ADVERTISING, name: 'Advertising'},
    {id: REFERRAL_CHOICES.REFERRAL_TRADE_ASSOCIATION, name: 'Trade Association'},
    {id: REFERRAL_CHOICES.REFERRAL_EMAIL, name: 'Email'},
    {id: REFERRAL_CHOICES.REFERRAL_DIRECT_MAIL, name: 'Direct Mail'},
    {id: REFERRAL_CHOICES.REFERRAL_TRADE_SHOW, name: 'Trade Show'},
    {id: REFERRAL_CHOICES.REFERRAL_STANDARD, name: 'Referral'},
    {id: REFERRAL_CHOICES.REFERRAL_GSA, name: 'GSA Advantage'},
    {id: REFERRAL_CHOICES.REFERRAL_OTHER, name: 'Other'},
];

export enum APPLICATION_STATUS_CHOICES {
    STATUS_NEW = 0,
    STATUS_DENIED = 1,
    STATUS_ACCEPTED = 2,
    STATUS_PENDING = 3
}

export const APPLICATION_STATUS_ITEMS = [
    {id: APPLICATION_STATUS_CHOICES.STATUS_NEW, name: 'New'},
    {id: APPLICATION_STATUS_CHOICES.STATUS_DENIED, name: 'Denied'},
    {id: APPLICATION_STATUS_CHOICES.STATUS_ACCEPTED, name: 'Accepted'},
    {id: APPLICATION_STATUS_CHOICES.STATUS_PENDING, name: 'Pending'}
];

export class WholesaleApplication extends Model {
    static override uri: string = '/profile/api/user/wholesale-application/:id/';
    static override objects: APIResource<WholesaleApplication>;

    @field(Field, {
        required: true
    })
    name: string;

    @field(ForeignKeyField, {
        model: 'User',
        readOnly: true
    })
    customer: any;

    @field(Field, {
        required: true
    })
    company_name: string;

    @field(Field, {
        required: true
    })
    address: string;

    @field(Field, {
        required: true
    })
    city: string;

    @field(Field, {
        required: true
    })
    state: string;

    @field(Field, {
        required: true
    })
    postal_code: string;

    @field(Field, {
        required: true
    })
    country: string;

    @field(Field, {
        required: true
    })
    email: string;

    @field(Field, {
        required: true
    })
    phone_number: string;

    @field(Field, {
        required: true
    })
    employees: BUSINESS_SIZE_CHOICES;

    @field(Field, {
        required: true
    })
    business_description: BUSINESS_TYPE_CHOICES;

    @field(Field, {
        required: true
    })
    referral: REFERRAL_CHOICES;

    @field()
    application_status: APPLICATION_STATUS_CHOICES;

    @field()
    date_created: string;

    @field(ToManyField, {
        model: 'WholesaleMembership'
    })
    membership_applications: Collection<WholesaleMembership>;

    get employees_option_name() {
        for (const choice of BUSINESS_SIZE_ITEMS) {
            if (choice.id == this.employees) {
                return choice.name;
            }
        }
    }

    get business_description_option_name() {
        for (const choice of BUSINESS_TYPE_CHOICES_ITEMS) {
            if (choice.id == this.business_description) {
                return choice.name;
            }
        }
    }

    get referral_option_name() {
        for (const choice of REFERRAL_CHOICES_ITEMS) {
            if (choice.id == this.referral) {
                return choice.name;
            }
        }
    }

    get application_status_name() {
        for (const choice of APPLICATION_STATUS_ITEMS) {
            if (choice.id == this.application_status) {
                return choice.name;
            }
        }
    }

    get employees_choices() {
        return BUSINESS_SIZE_ITEMS;
    }

    get business_description_choices() {
        return BUSINESS_TYPE_CHOICES_ITEMS;
    }

    get referral_choices() {
        return REFERRAL_CHOICES_ITEMS;
    }

    get application_status_choices() {
        return APPLICATION_STATUS_ITEMS;
    }
}