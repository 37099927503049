export const html: string = `
<div class="work-order-item">
    <div class="col-xs-12 col-full work-order-labels hidden-sm hidden-xs">
        <div class="col-xs-2">
            Customer File
        </div>
        <div class="col-xs-2">
            Processed File
        </div>
        <div class="col-xs-6">
            Information
        </div>
        <div class="col-xs-2">
            Status
            <span class="work-order-status">
                {{ workOrder.status_name }}
            </span>
        </div>
    </div>
    <div class="col-xs-12 col-full work-order-block">
        <div class="col-md-2 col-xs-6 work-order-image-block">
            <div class="col-xs-12 col-full work-order-labels hidden-lg hidden-md">
                Customer File
            </div>
            <i class="fa fa-eye view-image-icon" @click="viewCustomerPreview(workOrder)"></i>
            <img :src="workOrder.raster_url" @click="viewCustomerPreview(workOrder)">
            <div v-show="!workOrder.is_done" v-click-outside="closeDropdown">
                <a class="btn btn-default" @click="show_download_options = !show_download_options">Download</a>
                <div class="download-dropdown-area" v-show="show_download_options">
                    <div class="download-item" v-for="item in workOrder.downloads" @click="downloadItem(workOrder, item)">
                        {{ item }}
                    </div>
                    <div class="download-item" @click="downloadItem(workOrder, null)">
                        All Items
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-xs-6 work-order-image-block">
            <div class="col-xs-12 col-full work-order-labels hidden-lg hidden-md">
                Processed File
            </div>
            <i class="fa fa-eye view-image-icon" @click="viewProcessedPreview(workOrder)"></i>
            <img :src="workOrder.active_file?.raster ? workOrder.active_file.raster : '/static/img/processing/no-processed-file.svg'" @click="viewProcessedPreview(workOrder)" >
            <button class="btn btn-default" @click="openUploadModal()" v-show="!workOrder.is_done">Upload</button>
        </div>
        <div class="col-md-6 col-xs-12 work-order-info">
            <div class="col-xs-12 col-full work-order-labels hidden-lg hidden-md">
                Information
            </div>
            <div class="col-xs-12 col-full">
                <h3 v-if="workOrder.data"><a :href="workOrder.data.link">{{ workOrder.data.product_name }}</a></h3>
            </div>
            <div class="work-order-info-column col-full-left">
                <div class="item" v-if="workOrder.rush">
                    <label>Rush:</label>
                    <span><b style="color: red" v-for="service in workOrder.service_list">Yes</b></span>
                </div>
                <div class="item">
                    <label>Type:</label>
                    <span><b :style="{color: service.color}" v-for="service in workOrder.service_list">{{ service.name }}</b></span>
                </div>
                <div class="item">
                    <label>Order Date:</label>
                    <span>{{ $filters.date(workOrder.date_created) }}</span>
                </div>
                <div class="item">
                    <label>Reference ID:</label>
                    <span>{{ workOrder.reference_id }}</span>
                </div> 
                <div class="item">
                    <label>Size:</label>
                    
                    <div class="edit-item edit-size" v-show="!edit_size">
                        <span v-show="!edit_size">
                            {{ workOrder.width }}" x {{ workOrder.height }}"
                            <i class="far fa-pen-square edit-item-btn clickable" @click="edit_size = true"></i>
                        </span>
                    </div>
                    
                    <div class="edit-item edit-size" v-show="edit_size">
                        <error-list :errors="workOrder.errors" field="size"></error-list>
                        <div class="flex-group">
                            <input v-model="workOrder.width">
                            <i class="fa fa-times"></i>
                            <input v-model="workOrder.height">
                            <i class="far fa-times-square cancel-edit-item-btn edit-state clickable" @click="workOrder.revert(); edit_size = false"></i>
                            <i class="far fa-save edit-item-btn edit-state clickable" @click="updateSize()"></i>
                        </div>
                    </div>
                </div>
                <div class="item" v-for="option in workOrder.normal_options">
                    <label>{{ option.label }}</label>
                    <span>{{ option.value }}</span>
                </div>
                <div class="item">
                    <label><i class="fa fa-sticky-note"></i>Notes ({{ workOrder.note_count }})</label>
                    <span>
                        <a @click="openNotes()">
                            {{ workOrder.is_done || workOrder.is_canceled ? 'View Notes' : 'Add/View Notes' }}
                        </a>
                    </span>
                </div> 
            </div>
            <div class="work-order-info-column col-full-left">
                <div class="item" v-for="option in workOrder.important_options">
                    <label>{{ option.label }}</label>
                    <span>{{ option.value }}</span>
                </div> 
            </div>
            <hr class="col-xs-12" v-if="workOrder.large_options?.length">
            <div class="col-xs-12 col-full" v-if="workOrder.large_options?.length">
                <div class="large-item" v-for="option in workOrder.large_options">
                    <label>{{ option.label }}</label>
                    <span>{{ option.value }}</span>
                </div>
            </div>
            <hr class="col-xs-12" v-if="workOrder.rejection_reason">
            <div class="col-xs-12 col-full" v-if="workOrder.rejection_reason">
                <div class="large-item">
                    <label>File Rejection Note</label>
                    <span>{{ workOrder.rejection_reason }}</span>
                </div>
            </div>
        </div>
        <div class="col-xs-12 work-order-labels hidden-lg hidden-md">
            Status
            <span class="work-order-status">
                {{ workOrder.status_name }}
            </span>
        </div>
        <div class="col-md-2 col-xs-12">
            <div class="status-item reject" @click="rejectWorkOrder(workOrder)" v-show="workOrder.is_not_done">
                <i class="fa fa-times"></i>
                Reject Work Order
            </div>
            <div v-for="item in workOrder.work_order_files" @click="viewWorkOrderUpload(item)">
                <div class="status-item" :class="{'approved': item.is_accepted, 'rejected': item.is_rejected, 'pending': item.is_pending, 'removed': item.is_removed, 'needs-approval': item.needs_approval}">
                    <img :src="item.status_icon"> 
                    {{ item.status_name }}
                </div>
            </div>
            <div class="status-item no-file" v-if="workOrder.work_order_files?.length == 0">
                No File Submitted
            </div>
        </div>
    </div>
</div>
`;