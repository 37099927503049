export const html:string = `
<div class="modal" id="loginModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body clearfix">
                <button type="button" class="close login-close" @click="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <account-login v-if="isOpen" :modal="true"></account-login>
            </div>
        </div>
    </div>
</div>
`;