import {html} from './WorkOrderItem.html';
import WorkOrderService from '../../services/WorkOrderService';
import {openModal} from '../../../../../../common/ts/core/utils/Modal';
import VueComponent, {data, method, prop} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import {WorkOrder} from '../../models/WorkOrder';

class WorkOrderItemController extends VueComponent {
    /*
        Make sure the click outside dropdown is correctly working
     */

    @prop()
    workOrder: WorkOrder;

    @data()
    show_download_options: boolean;

    @data()
    work_order_service: WorkOrderService;

    @data()
    edit_size: boolean;

    constructor(component) {
        super(component);
        this.work_order_service = Services.get<WorkOrderService>('WorkOrderService');
    }

    @method()
    openUploadModal() {
        this.work_order_service.setup(this.workOrder, null);
        openModal('upload-work-order');
    }

    @method()
    viewWorkOrderUpload(item) {
        this.work_order_service.setup(this.workOrder, item);
        openModal('view-work-order');
    }

    @method()
    openNotes() {
        this.work_order_service.setup(this.workOrder, null);
        openModal('view-work-order-note')
    }

    @method()
    viewCustomerPreview(work_order) {
        this.work_order_service.setup(work_order, work_order.active_file);
        openModal('view-large-customer-image-modal');
    }

    @method()
    viewProcessedPreview(work_order) {
        this.work_order_service.setup(work_order, work_order.active_file);
        openModal('view-large-processed-image-modal');
    }

    @method()
    rejectWorkOrder(work_order) {
        this.work_order_service.setup(work_order, null);
        openModal('reject-work-order-modal');
    }

    @method()
    downloadItem(work_order: WorkOrder, item: string) {
        let url = new URL(window.location.origin + '/processing/download/');

        url.searchParams.set('work_order_id', `${work_order.id}`);

        if (item) {
            url.searchParams.set('item', item);
        }

        window.open(url);
    }

    @method()
    closeDropdown() {
        this.show_download_options = false;
    }

    @method()
    updateSize() {
        if (!this.workOrder.sizeIsValid(this.workOrder.width, this.workOrder.height)) {
            return;
        }

        this.workOrder.save();
        this.edit_size = false
    }
}

export default function WorkOrderItem(): IVueComponent {
    return {
        controller: WorkOrderItemController,
        template: html,
        tag: 'work-order-item'
    };
}