import {Model, TModelDefinitionList} from '../../../../../common/ts/core/models/Model';
import {Invoice} from './Invoice';
import {ServiceFulfillment} from './ServiceFulfillment';
import {ServicePrepayment} from './ServicePrepayment';

export default function registerModels():  void {
    const models: TModelDefinitionList = {
        Invoice: Invoice,
        ServiceFulfillment: ServiceFulfillment,
        ServicePrepayment: ServicePrepayment
    };

    Model.registerModels(models);
}