export const html:string = `
<div class="dropdown image-dropdown" v-dropdown>
    <button class="btn dropdown-toggle dropdown-ghost btn-default" type="button">
        <div v-if="selected && selected.preview" class="name item-image" :style="{'background-image': 'url(' + selected.preview + ')' }"></div>
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu">
        <li v-for="item in items" @click="select(item)" class="item-image" :class="{selected : item == selected}">
            <div :style="{ 'background-image': 'url(' + item.preview + ')' }" :alt="item.name" :title="item.name"></div>
        </li>
    </ul>
</div>
`;