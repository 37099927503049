import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';
import {WorkOrderFile} from './WorkOrderFile'
import {APIResource, IAPIResourceCallback} from '../../../../../common/ts/core/models/APIResource';
import {IPromise} from '../../../../../common/ts/core/utils/SimplePromise';
import {Services} from '../../../../../common/ts/core/services/Services';
import {MaxSizeAbstract} from '../../../../../common/ts/apps/svgcustom/models/abstract/MaxSizeAbstract';

export class WorkOrder extends MaxSizeAbstract {
    public static override uri: string = '/processing/api/v1/work-order/:id/';
    static override objects: APIResource<WorkOrder>;

    static NOT_DONE = 0;
    static PENDING = 1;
    static DONE = 2;
    static CANCELED = 3;
    static REJECTED = 4;

    @field()
    reference_id: string;

    @field()
    user;

    @field()
    date_created;

    @field()
    last_updated;

    @field()
    width;

    @field()
    height;

    @field()
    stored_data;

    @field()
    file_url;

    @field()
    raster_url;

    @field()
    payment;

    @field()
    site;

    @field()
    status: number;

    @field()
    note_count: number;

    @field()
    rejection_reason: string;

    @field()
    service_list: any;

    @field()
    rush: boolean;

    override reload(callback?: IAPIResourceCallback, error_callback?: IAPIResourceCallback): IPromise<Model> {
        this._active_file = null;
        this._work_order_files = null;
        this._important_options = null;
        this. _normal_options = null;
        this._large_options = null;

        return super.reload(callback, error_callback);
    }

    private _active_file: WorkOrderFile;
    get active_file() {
        if (this._active_file) {
            return this._active_file;
        }

        if (!this._work_order_files) {
            this.work_order_files;
            return null;
        }

        let files = this.work_order_files;
        for (const file of files) {
            if (file.status == WorkOrderFile.ACCEPTED) {
                this._active_file = file;
            }
            else if (file.status == WorkOrderFile.PENDING && (!this._active_file || this.active_file.status != WorkOrderFile.ACCEPTED)) {
                this._active_file = file;
            }
        }

        return this._active_file;
    }

    private _work_order_files: WorkOrderFile[];
    get work_order_files(): WorkOrderFile[] {
        if (!this._work_order_files && this.id) {
            this._work_order_files = [];
            let response = Services.get<typeof WorkOrderFile>('WorkOrderFile').objects.filter({work_order: this.id});
            response.$promise.then(() => {
                this._work_order_files = response.items as any;
            })
        }

        return this._work_order_files;
    }

    reload_files() {
        this._work_order_files = null;
        return this.work_order_files;
    }

    get status_name() {
        switch (this.status) {
            case WorkOrder.DONE:
                return 'Done';
            case WorkOrder.PENDING:
                return 'Pending';
            case WorkOrder.NOT_DONE:
                return 'New';
            case WorkOrder.REJECTED:
                return 'Rejected';
            default:
                return 'New'
        }
    }

    get data() {
        return JSON.parse(this.stored_data);
    }

    private _important_options;
    private _normal_options;
    private _large_options;
    get important_options() {
        if (!this._important_options) {
            this._important_options = [];
            for (const item of this.data.options) {
                if (item.important && !item.large) {
                    this._important_options.push(item);
                }
            }
        }

        return this._important_options;
    }

    get normal_options() {
        if (!this._normal_options) {
            this._normal_options = [];
            for (const item of this.data.options) {
                if (!item.important && !item.large) {
                    this._normal_options.push(item);
                }
            }
        }

        return this._normal_options;
    }

    get large_options() {
        if (!this._large_options) {
            this._large_options = [];
            for (const item of this.data.options) {
                if (item.large) {
                    this._large_options.push(item);
                }
            }
        }

        return this._large_options;
    }

    get downloads() {
        return this.data.downloads || []
    }

    get is_done() {
        return this.status == WorkOrder.DONE;
    }

    get is_not_done() {
        return this.status == WorkOrder.NOT_DONE;
    }

    get is_pending() {
        return this.status == WorkOrder.PENDING;
    }

    get is_canceled() {
        return this.status == WorkOrder.CANCELED;
    }

    get is_rejected() {
        return this.status == WorkOrder.REJECTED;
    }
}