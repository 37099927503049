// For some reason webpack is incorrectly compiling and placing the enums after they are referenced
export enum MainMenuStates {
    REGISTER,
    RESET_PASSWORD,
    LOGIN
}

export enum LoginState {
    HOME,
    REGISTER,
    COMPLETE
}

export enum SignUpState {
    ENTER_EMAIL,
    SENT
}

export const html:string = `
<div id="account-login">
    <div v-show="currentState == ${MainMenuStates.REGISTER}">
        <div class="col-xs-12">
            <form v-show="loginRegisterSubState == ${LoginState.HOME}" @submit.prevent="submitLogin()">
                <div class="form-group">
                    <span class="modal-title">Account Sign In</span>
                    <span class="modal-title-helper">Log in for faster checkout.</span>
                </div>
                <error-list :field="'unexpected'" :errors="errors.loginForm"></error-list>
                <div class="form-group">
                    <label>Email Address</label>
                    <error-list :field="'username'" :errors="errors.loginForm"></error-list>
                    <input type="email" class="form-control" id="id_username" name="username" v-model="username_email" autocomplete="username email" required>
                </div> 
                <div class="form-group clearfix">
                    <label>Password</label> 
                    <error-list :field="'password'" :errors="errors.loginForm"></error-list>
                    <input type="password" id="id_password" name="password" class="form-control" v-model="password" autocomplete="current-password" required>
                    <a class="forgot" @click="currentState = ${MainMenuStates.RESET_PASSWORD}">Forgot Password?</a> 
                </div>
                <error-list :field="'__all__'" :errors="errors.loginForm"></error-list>
                <div class="form-group row">
                    <div class="col-xs-6"> 
                        <a class="btn btn-ghost" @click="loginRegisterSubState = ${LoginState.REGISTER}">Register</a>
                    </div>
                    <div class="col-xs-6">
                        <button id="login" class="btn btn-ghost inverted" @click="submitLogin($event)">Login</button>
                    </div> 
                </div>  
            </form>
            <form class="form-group" v-show="loginRegisterSubState == ${LoginState.REGISTER}" @submit.prevent="register()">
                <div class="form-group">
                    <span class="modal-title">Account Creation</span>
                    <span class="modal-title-helper">Create a password to save information for faster checkout.</span>
                </div>
                <error-list :field="'unexpected'" :errors="errors.registerForm"></error-list>
                <div class="form-group">
                    <label>Email Address</label>
                    <error-list :field="'email'" :errors="errors.registerForm"></error-list>
                    <input type="email" class="form-control" name="email" v-model="username_email" autocomplete="username email" required>
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <error-list :field="'password'" :errors="errors.registerForm"></error-list>
                    <input type="password" class="form-control" name="password" v-model="password" autocomplete="new-password" required>
                </div>
                <div class="form-group">
                    <label>Retype Password</label>
                    <error-list :field="'password2'" :errors="errors.registerForm"></error-list>
                    <input type="password" class="form-control" name="password2" v-model="password_verification" autocomplete="new-password" required>
                </div>
                <error-list :field="'__all__'" :errors="errors.registerForm"></error-list>
                <div class="form-group row create">
                    <div class="col-xs-6 i-am-a"> 
                        <a @click="cancelRegister()">I Have An Account.</a>
                    </div>
                    <div class="col-xs-6">
                        <button class="btn btn-ghost" @click="register()">Create Account</button>
                    </div> 
                </div>
            </form>
            <div class="form-group" v-show="loginRegisterSubState == ${LoginState.COMPLETE}">
                <div>
                    <span class="modal-sub-title">Register</span>
                </div>
                <div class="form-group">
                    {{responses.registerForm}}
                </div>
                <br>
                <div class="form-group row">
                    <div class="col-xs-6 pull-right">
                        <button v-if="modal" class="btn btn-ghost" @click="close()" aria-label="Close Window">Close Window</button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="form-group"> 
                <span class="modal-sub-title">Or Sign In With:</span>
                <div class="col-sm-4 col-xs-12 alt-method google" @click="googleLogin()">
                    <img class="alt-img" src="/static/img/google-logo.svg"/>
                    <span>Google</span>
                </div>
                <div class="col-sm-4 col-xs-12 alt-method middle facebook" @click="facebookLogin()">
                    <i class="fab fa-facebook"></i>
                    <span>Facebook</span> 
                </div>
                <div class="col-sm-4 col-xs-12 alt-method email" @click="currentState = ${MainMenuStates.LOGIN}"> 
                    <div class="email-circle">
                        <img class="envelope" src="/static/img/mail-icon.svg"/>
                    </div>
                    <span>Email</span>
                </div>
            </div>
        </div>
    </div>
    <div v-show="currentState == ${MainMenuStates.RESET_PASSWORD}">
        <div v-show="passwordResetSubState == ${SignUpState.ENTER_EMAIL}">
            <form class="col-xs-12" @submit.prevent="resetPassword()">
                <div class="form-group">
                    <span class="modal-title reset-title">Reset Password</span>
                    Forgot your password? No problem. Enter your email below to start the process of resetting your password. 
                </div>
                <error-list :field="'unexpected'" :errors="errors.resetPasswordForm"></error-list>
                <div class="form-group">
                    <label>Email Address</label>
                    <error-list :field="'email'" :errors="errors.resetPasswordForm"></error-list>
                    <input type="email" class="form-control" name="email" v-model="username_email" required>
                </div>
                <error-list :field="'__all__'" :errors="errors.resetPasswordForm"></error-list>
                <div class="form-group row">
                    <div class="col-xs-6 i-am-a">
                        <a class="i-am-a" @click="resetStates()">Cancel</a>
                    </div> 
                    <div class="col-xs-6">
                        <button class="btn btn-ghost" @click="resetPassword($event)">Continue</button>
                    </div>
                </div>
            </form>
        </div>
        <div v-show="passwordResetSubState == ${SignUpState.SENT}">
            <div class="col-xs-12">
                <div class="form-group">
                    <span class="modal-title reset-title">Reset Password</span> 
                </div>
                <div class="form-group">
                    {{ responses.resetPasswordForm }}
                </div>
                <br>
                <div class="form-group row">
                    <div class="col-xs-6 pull-right">
                        <button v-if="modal" class="btn btn-ghost" @click="close()" aria-label="Close Window">Close Window</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-show="currentState == ${MainMenuStates.LOGIN}">
        <div v-show="emailLoginSubState == ${SignUpState.ENTER_EMAIL}">
            <form class="col-xs-12" @submit.prevent="emailLogin()">
                <div class="form-group">
                    <span class="modal-title">Request Mail Link for Log in</span>
                    <p>Enter your email to receive a link that will allow you to log in to your account. The link will expire in 30 minutes, so make sure to use it quickly.</p>
                </div>
                <error-list :field="'unexpected'" :errors="errors.emailLoginForm"></error-list>
                <div class="form-group">
                    <label>Email</label>
                    <error-list :field="'email'" :errors="errors.emailLoginForm"></error-list>
                    <input type="email" class="form-control" name="email" v-model="username_email" required>
                </div>
                <error-list :field="'__all__'" :errors="errors.emailLoginForm"></error-list>
                <div class="form-group row">
                    <div class="col-xs-6 i-am-a">
                        <a @click="resetStates()">Back</a>
                    </div>
                    <div class="col-xs-6">
                        <button class="btn btn-ghost" @click="emailLogin($event)">Continue</button>
                    </div>
                </div>
            </form>
        </div>
        <div v-show="emailLoginSubState == ${SignUpState.SENT}">
            <div class="col-xs-12">
                <div class="form-group">
                    <span class="modal-title">Request Mail Link for Log in</span>
                    <p>Your Email Link should be in your inbox.</p>
                </div>
                <div class="form-group">
                    {{ responses.emailLoginForm }}
                </div>
                <div class="form-group row">
                    <div class="col-xs-6 pull-right">
                        <button v-if="modal" class="btn btn-ghost" @click="close()" aria-label="Close Window">Close Window</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pull-right terms-footer col-xs-12">
        <a href="/terms-and-conditions/">Terms and Conditions</a> | <a href="/privacy-policy/">Privacy Policy</a>
    </div>
</div>
`;
