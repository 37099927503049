import {html} from './ViewWorkOrderModal.html';
import WorkOrderService from '../../services/WorkOrderService';
import {WorkOrderFile} from '../../models/WorkOrderFile';
import {WorkOrderNote} from '../../models/WorkOrderNote';
import {ModalController} from '../../../../../../common/ts/core/adapters/ModalComponent';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import {Http} from '../../../../../../common/ts/core/services/Http';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';


export class ViewWorkOrderModalController extends ModalController {

    @data()
    work_order_service: WorkOrderService;

    @data()
    notes: APIResponse<WorkOrderNote>;

    constructor(component) {
        super(component, 'view-work-order');

        this.work_order_service = Services.get<WorkOrderService>('WorkOrderService');
    }

    @method()
    remove() {
        this.work_order_service.work_order_file.status = WorkOrderFile.REMOVED;
        this.work_order_service.work_order_file.save().then(() => {
            this.close();
            this.work_order_service.work_order.reload();
        }, (response) => {
            this.close();
            this.work_order_service.work_order.reload();
        });
    }

    @method()
    restore() {
        this.work_order_service.work_order_file.status = WorkOrderFile.PENDING;
        this.work_order_service.work_order_file.save().then(() => {
            this.close();
            this.work_order_service.work_order.reload();
        }, () => {
            this.close();
            this.work_order_service.work_order.reload();
        });
    }

    @method()
    approve() {
        Services.get<Http>('$http').request({
            url: '/processing/api/approve-work-order-file/',
            method: 'POST',
            data: {
                work_order_file: this.work_order_service.work_order_file.id
            }
        }).then(() => {
            this.close();
            this.work_order_service.work_order.reload();
            this.work_order_service.trigger('status-change');
        }, () => {
            this.close();
            this.work_order_service.work_order.reload();
            this.work_order_service.trigger('status-change');
        })
    }

    override onOpen() {
        super.onOpen();
    }
}

export default function ViewWorkOrderModal(): IVueComponent {
    return {
        controller: ViewWorkOrderModalController,
        template: html,
        tag: 'view-work-order-modal'
    };
}
