import {OrderedItem} from '../../apps/order/models/OrderedItem';
import APIResponse from '../models/APIResponse';
import {Order} from '../../apps/order/models/Order';
import FacebookTracker from './trackers/Facebook';
import GTagTracking from './trackers/GTag';
import UniversalAnalyticsTracking from './trackers/UniversalAnalytics';

export interface AnalyticsTracking {
    trackPage(page: string, title?: string);
    trackEvent(category, action, label?, value?);
    trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>);
    trackAddedProductToCart(product_id, price, quantity);
    trackProductPage(sku, default_price);
}

export class Analytics {
    trackers: AnalyticsTracking[];

    constructor() {
        this.trackers = [
            new FacebookTracker(),
            new GTagTracking()
        ]
    }

    public trackPage(page: string, title?: string) {
        for (const tracker of this.trackers) {
            tracker.trackPage(page, title);
        }
    }

    public trackEvent(category, action, label?, value?) {
        for (const tracker of this.trackers) {
            tracker.trackEvent(category, action, label, value);
        }
    }

    public trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>) {
        for (const tracker of this.trackers) {
            tracker.trackCheckout(order, ordered_items);
        }
    }

    public trackAddedProductToCart(product_id, price, quantity) {
        for (const tracker of this.trackers) {
            tracker.trackAddedProductToCart(product_id, price, quantity);
        }
    }

    public trackProductPage(sku, default_price) {
        for (const tracker of this.trackers) {
            tracker.trackProductPage(sku, default_price);
        }
    }
}
