import InjectedService from '../../../core/services/InjectedService';
import {ProcessedFileFolder} from "../../processing/models/ProcessedFileFolder";
import APIResponse from '../../../core/models/APIResponse';

export default class UserProductService extends InjectedService {
    public static $inject : string[] = [
        'ProcessedFileFolder',
        'UserProfileService'
    ];

    protected folders : APIResponse<ProcessedFileFolder>;

    constructor(protected ProcessedFileFolder, protected UserProfileService) {
        super();
    }

    public reset() : void {
        this.folders = null;
    }
}
