import {Model} from '../../../core/models/Model';
import {Field, field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ClipartCategory} from '../../svgcustom/models/ClipartCategory';
import {WholesaleAccount} from './WholesaleAccount';
import {APIResource} from '../../../core/models/APIResource';

export class WholesaleMembership extends Model {
    static override uri: string = '/profile/api/user/wholesale-membership/:id/';
    static override objects: APIResource<WholesaleMembership>;

    @field(ForeignKeyField, {
        model: 'WholesaleAccount'
    })
    account: WholesaleAccount;

    @field(Field, {
        required: true
    })
    account_number: string;

    @field()
    expiration: string;

    @field()
    enabled: boolean;
}