import {html} from './EllipsisText.html';
import IVueComponent from '../../adapters/IVueComponent';
import VueComponent, {prop} from '../../adapters/VueComponent';

export class EllipsisTextController extends VueComponent {
    @prop()
    append;

    @prop()
    staticContent;

    public fail: boolean;

    override mounted() {
        this.$el.textContent = this.$props.staticContent || this.$props.content;
        this.fixOverflow();
    }

    public fixOverflow(): void {
        const item = this.$el;
        if (this.fail) {
            return;
        }
        //Binary search and clip all overflowing text
        let contents: string = item.textContent;
        let savedContents: string = item.textContent;
        let left: number = 0;
        let right: number = contents.length;

        //Failsafe in the case that the items overflows without content
        let count: number = 0;

        while (left < right) {
            count += 1;
            if (count >= 12) {
                item.textContent = contents;
                this.fail = true;
                return;
            }

            const pos: number = left + Math.floor((right - left) / 2) + 1;

            item.textContent = contents.slice(0, pos);

            if (this.hasOverflow(item)){
                right = pos - 1;
            }
            else {
                left = pos;
            }
        }

        //If text was clipped, remove words till the contents of append will fit
        if (contents.length !== item.textContent.length) {
            contents = item.textContent;
            item.textContent = contents + '... ' + this.$props.append;

            count = 0;
            while (this.hasOverflow(item)) {
                count += 1;
                if (count >= 12) {
                    item.textContent = contents;
                    this.fail = true;
                    return;
                }

                contents = contents.slice(0, contents.lastIndexOf(' '));
                item.textContent = contents + '... ' + this.$props.append;
            }

            item.textContent = contents + '... '

            const append_el = document.createElement('a');
            append_el.id = 'ellipsis-expand';
            append_el.textContent = this.$props.append
            append_el.addEventListener('click', () => {
                item.textContent = savedContents;
                item.parentElement.style['max-height'] = 'unset';
            })
            item.appendChild(append_el);
        }
    }

    public hasOverflow(item): boolean {
        return item.parentElement.offsetHeight < item.offsetHeight;
    }
}

export default function EllipsisText(): IVueComponent {
    return {
        controller: EllipsisTextController,
        template: html,
        tag: 'ellipsis-text'
    };
}
