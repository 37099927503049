import ModalAttrBinding from './ModalAttrBinding';
import ScrollToBinding from './ScrollToBinding';

export abstract class Integration {
    constructor() {
        this.setupSentry();
        this.setupGlobalModules();
        this.setupModels();
        this.setupServices();
        this.configureServices();
        this.setupApps();
    }
    setupSentry(): void {};
    configureServices(): void {};
    setupGlobalModules(): void {
        ModalAttrBinding();
        ScrollToBinding();
    };

    abstract setupModels(): void;
    abstract setupServices(): void;
    abstract setupApps(): void;
}
