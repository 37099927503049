import RouteService from './RouteService';

export default class NamedRouteService {
    public static $inject: string[] = [
        'RouteService'
    ];

    protected routeService: RouteService
    constructor(routeService) {
        this.routeService = routeService;
    }

    public reverse(name: string, kwargs?: {[key: string]: any}): string {
        const route = this.routeService.getRouteFromName(name);

        if (!route) {
            return null;
        }

        if (!kwargs) {
            return route.path;
        }

        const url_parts = route.path.split('/');

        const used_keys = [];

        for (const key of Object.keys(kwargs)) {
            if (kwargs[key] == null) {
                continue;
            }
            for (let i = 0; i < url_parts.length; i++) {
                if (url_parts[i] === `:${key}*`) {
                    url_parts[i] = kwargs[key];
                    used_keys.push(key);
                }
                else if (url_parts[i] === `:${key}`) {
                    url_parts[i] = encodeURIComponent(kwargs[key]);
                    used_keys.push(key);
                }
            }
        }

        let base =  url_parts.join('/');

        let has_leftovers = false;
        let leftover_data = {};
        for (const key of Object.keys(kwargs)) {
            if (kwargs[key] == null) {
                continue;
            }
            if (used_keys.indexOf(key) === -1) {
                leftover_data[key] = kwargs[key];
                has_leftovers = true;
            }
        }

        if (has_leftovers) {
            let encoded = "";
            for (const key in leftover_data) {
                if (encoded != "") {
                    encoded += "&";
                }
                if (leftover_data[key] != ':page') {
                    encoded += encodeURIComponent(key) + "=" + encodeURIComponent(leftover_data[key]);
                }
                else {
                    encoded += encodeURIComponent(key) + "=" + leftover_data[key];
                }

            }
            return base + '?' + encoded;
        }
        return base;

    }
}
