export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>Invoices</h1>
    </div>
</div>

<div class="main-container">
    <table class="table table-striped">
        <thead>
            <th>Invoice ID</th>
            <th>Status</th>
            <th>Items</th>
            <th>Amount</th>
            <th>Date Created</th>
            <th>Date Paid</th>
        </thead>
        <tbody>
            <tr v-for="invoice in invoices.items">
                <td><a :href="$filters.route('payments:invoice-detail', {id: invoice.id})">{{ invoice.id }}</a></td>
                <td>{{ invoice.statusLabel }}</td>
                <th>{{ invoice.invoice_count }}</th>
                <td>{{ $filters.currency(invoice.price) }}</td>
                <td>{{ $filters.date(invoice.date_created) }}</td>
                <td>{{ $filters.date(invoice.date_paid) }}</td>
            </tr>
        </tbody>
    </table>
    <paginator :total="invoices.totalCount" :limit="limit" :page="page" :url="paginator_url" class="pull-right"></paginator>
</div>
`;
