import {html} from './ViewWorkOrderNote.html';
import WorkOrderService from '../../services/WorkOrderService';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';
import {WorkOrderNote} from '../../models/WorkOrderNote';
import ProcessingUserService from '../../../accounts/services/ProcessingUserService';
import {ModalController} from '../../../../../../common/ts/core/adapters/ModalComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';

export class ViewWorkOrderNoteController extends ModalController {
    @data()
    work_order_service: WorkOrderService;

    @data()
    processing_user_service: ProcessingUserService;

    @data()
    new_note: WorkOrderNote;

    constructor(component) {
        super(component, 'view-work-order-note');
        this.work_order_service = Services.get<WorkOrderService>('WorkOrderService');
        this.processing_user_service = Services.get<ProcessingUserService>('ProcessingUserService');
    }

    override reset() {
        super.reset();
        this.new_note = new WorkOrderNote({
            work_order: this.work_order_service.work_order,
            user: this.processing_user_service.user
        });
    }

    override onOpen() {
        this.reset();
    }

    @method()
    addNote($event) {
        const state: ButtonProcessingState = new ButtonProcessingState($event);
        state.process();

        this.new_note.save().then(() => {
            state.resolved();
            this.work_order_service.resetWorkOrderNotes();
            this.reset();
        }, () => {
            state.resolved();
        });
    }
}

export default function ViewWorkOrderNote(): IVueComponent {
    'use strict';

    return {
        controller: ViewWorkOrderNoteController,
        template: html,
        tag: 'view-work-order-note'
    };
}
