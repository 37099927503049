import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import axios from 'axios';
import {IPromise, SimplePromise} from '../utils/SimplePromise';

/*
    A wrapped axios request that tracks upload progress. This should only be needed for uploading larger files.
 */

export class Upload {
    promise: IPromise<AxiosResponse>;
    progress: number = 0;
    constructor(config: AxiosRequestConfig) {
        this.promise = this.handleRequest(Axios.request(this.patchConfig(config)));
    }

    private handleRequest(promise): IPromise<AxiosResponse> {
        const deferred = SimplePromise.defer<AxiosResponse>();
        promise.then((response) => {
            deferred.resolve(response);
        }).catch((error) => {
            if (error.isAxiosError) {
                deferred.reject(error);
            }
            else {
                throw error;
            }
        })

        return deferred.promise;
    }

    patchConfig(config) {
        let old_upload_progress = config['onUploadProgress'];

        config['onUploadProgress'] = (progressEvent) => {
            if (progressEvent.total) {
                this.progress = (progressEvent.loaded / progressEvent.total) * 100;
            }
            else {
                this.progress = 0;
            }

            if (old_upload_progress) {
                old_upload_progress(progressEvent);
            }
        }

        return config;
    }

    get percent() {
        return this.progress;
    }
}

export class HttpUpload {
    constructor() {
        axios.defaults.xsrfCookieName = 'csrftoken';
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    }

    request(config: AxiosRequestConfig): Upload {
        return new Upload(config);
    }
}