import {Invoice} from '../../models/Invoice';
import {ServiceFulfillment} from '../../models/ServiceFulfillment';
import {html} from './InvoiceDetail.html';
import {ServicePrepayment} from '../../models/ServicePrepayment';
import {Services} from '../../../../../../common/ts/core/services/Services';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import VueComponent, {data} from '../../../../../../common/ts/core/adapters/VueComponent';
import RouteService from '../../../../../../common/ts/core/services/RouteService';
import NamedRouteService from '../../../../../../common/ts/core/services/NamedRouteService';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';

class InvoiceDetailController extends VueComponent {

    @data()
    invoice: Invoice;

    @data()
    fulfillments: APIResponse<ServiceFulfillment>;

    @data()
    prepayments: APIResponse<ServicePrepayment>;

    @data()
    limit: number;

    @data()
    paginator_url: string;

    @data()
    page: number;

    constructor(component) {
        super(component);

        let params = Services.get<RouteService>('RouteService').current.params;

        this.page = parseInt(params.get('page')) || 1;
        this.limit = 30;
        this.paginator_url = Services.get<NamedRouteService>('$NamedRouteService').reverse('payments:invoice-detail', {
            id: params.get('id'),
            page: ':page'
        });

        let offset = (this.page - 1) * this.limit;

        this.invoice = Services.get<typeof Invoice>('Invoice').objects.get({
            id: params.get('id'),
        });

        this.fulfillments = Services.get<typeof ServiceFulfillment>('ServiceFulfillment').objects.filter({
            invoice: params.get('id'),
            limit: this.limit, offset: offset
        });

        if (this.page == 1) {
            this.prepayments = Services.get<typeof ServicePrepayment>('ServicePrepayment').objects.filter({
                invoice: params.get('id'),
            })
        }
    }
}

export default function InvoiceDetail(): IVueComponent {
    return {
        controller: InvoiceDetailController,
        template: html,
        tag: `invoice-detail`
    };
}
