export const html: string = `
<div id="reject-work-order-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="reject-work-order-modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel">Reject Work Order</h4>
            </div>
            <div class="modal-body clearfix">
                <label>Reason For Rejection</label>
                <error-list :errors="errors" field="reason"></error-list>
                <textarea v-model="reason"></textarea>
                <error-list :errors="errors" field="__all__"></error-list>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning">Cancel</button>
                <button class="btn btn-primary" @click="submitRejection($event)">Submit</button>
            </div>
        </div>
    </div>
</div>
`;