import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export class WorkOrderFile extends Model {
    public static override uri: string = '/processing/api/v1/work-order-file/:id/';
    static override objects: APIResource<WorkOrderFile>;

    static PENDING = 0;
    static ACCEPTED = 1;
    static REJECTED = 2;
    static REMOVED = 3;
    static NEEDS_APPROVAL = 4;

    @field()
    status: number;

    @field()
    work_order: any;

    @field()
    file: string;

    @field()
    raster: string;

    @field()
    customer_file: string;

    @field()
    customer_raster: string;

    @field()
    user: any;

    public get status_name() {
        switch (this.status) {
            case WorkOrderFile.PENDING:
                return 'Pending Review';

            case WorkOrderFile.ACCEPTED:
                return 'File Accepted';

            case WorkOrderFile.REJECTED:
                return 'File Rejected';

            case WorkOrderFile.REMOVED:
                return 'File Removed';

            case WorkOrderFile.NEEDS_APPROVAL:
                return 'Needs Approval';

            default:
                return ''
        }
    }

    public get status_icon() {
        switch (this.status) {
            case WorkOrderFile.PENDING:
                return '/static/img/processing/pending-review-icon.svg';

            case WorkOrderFile.ACCEPTED:
                return '/static/img/processing/file-approved-icon.svg';

            case WorkOrderFile.REJECTED:
                return '/static/img/processing/file-rejected-icon.svg';

            case WorkOrderFile.REMOVED:
                return '/static/img/processing/file-removed-icon.svg';

            case WorkOrderFile.NEEDS_APPROVAL:
                return '';

            default:
                return ''
        }
    }

    public get is_accepted() {
        return this.status == WorkOrderFile.ACCEPTED;
    }

    public get is_pending() {
        return this.status == WorkOrderFile.PENDING;
    }

    public get is_rejected() {
        return this.status == WorkOrderFile.REJECTED;
    }

    public get is_removed() {
        return this.status == WorkOrderFile.REMOVED;
    }

    public get needs_approval() {
        return this.status == WorkOrderFile.NEEDS_APPROVAL;
    }
}