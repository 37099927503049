import {field} from '../../../../../common/ts/core/models/fields/Field';
import {Model} from '../../../../../common/ts/core/models/Model';
import {APIResource} from '../../../../../common/ts/core/models/APIResource';

export enum EInvoiceStatus {
    NOT_PAID,
    PAID,
    ERROR
}

export class Invoice extends Model {
    public static override readonly uri: string = '/payments/api/v1/invoice/:id/';
    static override objects: APIResource<Invoice>;

    @field()
    status: EInvoiceStatus;

    @field()
    transaction_id: number;

    @field()
    price: any;

    @field()
    date_created: string;

    @field()
    date_paid: string;

    @field()
    invoice_count: number;

    get statusLabel(): string {
        switch (this.status) {
            case EInvoiceStatus.NOT_PAID:
                return 'Not Paid';
            case EInvoiceStatus.PAID:
                return 'Paid';
            default:
                return 'Error';
        }
    }
}
