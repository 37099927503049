import {html} from './PendingWorkOrder.html';
import {WorkOrder} from '../../models/WorkOrder';
import APIResponse from '../../../../../../common/ts/core/models/APIResponse';
import VueComponent, {data} from '../../../../../../common/ts/core/adapters/VueComponent';
import {Services} from '../../../../../../common/ts/core/services/Services';
import RouteService from '../../../../../../common/ts/core/services/RouteService';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import NamedRouteService from '../../../../../../common/ts/core/services/NamedRouteService';

class PendingWorkOrderController extends VueComponent {

    @data()
    work_orders: APIResponse<WorkOrder>;

    @data()
    limit: number;

    @data()
    paginator_url: string;

    @data()
    page: number;

    constructor(component) {
        super(component);

        let params = Services.get<RouteService>('RouteService').current.params;

        this.page = parseInt(params.get('page')) || 1;
        this.limit = 20;

        this.paginator_url = Services.get<NamedRouteService>('$NamedRouteService').reverse('work-orders:pending', {page: ':page'});

        let offset = (this.page - 1) * this.limit;

        this.work_orders = Services.get<typeof WorkOrder>('WorkOrder').objects.filter({status: WorkOrder.PENDING, limit: this.limit, offset: offset});
    }
}

export default function PendingWorkOrder(): IVueComponent {
    return {
        controller: PendingWorkOrderController,
        template: html,
        tag: `pending-work-order`
    };
}
