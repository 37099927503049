import {EventDispatcher} from 'simple-ts-event-dispatcher';

export default class Debug extends EventDispatcher {
    public mounted_list: string[];
    public app_component_list: any;

    constructor() {
        super();

        this.mounted_list = [];
        this.app_component_list = {};
    }
}