import {modal} from './Modal';

export default function ModalAttrBinding() {
    for (const item of document.querySelectorAll('[data-modal-action]')) {
        item.addEventListener('click', () => {
            if (item.attributes['data-modal-action']?.value) {
                if (item.attributes['data-modal-event']?.value) {
                    modal[item.attributes['data-modal-action'].value](item.attributes['data-modal-event']);
                }
                else {
                    modal[item.attributes['data-modal-action'].value]();
                }
            }
        })
    }
}

