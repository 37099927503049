
export class ResourceURI {
    public uri: string;
    private _idRegex: RegExp;

    constructor(uri) {
        this.uri = uri;
        this.idRegex = /\/([0-9]+)\//;
    }

    set idRegex(regex) {
        this._idRegex = new RegExp(regex);
    }

    get id() {
        return parseInt(this._idRegex.exec(this.uri)[1]);
    }
}
