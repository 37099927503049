import VueItem, {DataItem, PropItem} from './VueItem';

// Makes the variable callable and will emit
export function emit() {
    return function(target: any, key: string) {
        let target_class = target.constructor as typeof VueItem;

        if (!target_class.hasOwnProperty('class_emits_list')) {
            target_class.class_emits_list = [];
        }

        target_class.class_emits_list.push(key);
    };
}

// Provides access to the data in the html
export function prop(data?: PropItem) {
    return function(target: any, key: string) {
        let target_class = target.constructor as typeof VueItem;

        if (!target_class.hasOwnProperty('class_props_list')) {
            target_class.class_props_list = [];
        }

        target_class.class_props_list.push({name: key, ...data});
    };
}

// Provides access to the data in the html
export function data(config?: DataItem) {
    return function(target: any, key: string) {
        if (key == 'data') {
            // This will cause the component to throw an exception and brick the component
            console.error('A @data binding was set for the reserved keyword "data".');
            return;
        }

        let target_class = target.constructor as typeof VueItem;

        if (!target_class.hasOwnProperty('class_bound_data')) {
            target_class.class_bound_data = [];
        }

        target_class.class_bound_data.push(key);

        if (config && config.ref) {
            if (!target_class.hasOwnProperty('class_ref_data_map')) {
                target_class.class_ref_data = {};
            }

            target_class.class_ref_data[key] = config.ref;
        }
    };
}

// Provides access to the method in the html
export function method() {
    return function(target: any, key: string) {
        let target_class = target.constructor as typeof VueItem;

        if (!target_class.hasOwnProperty('class_methods_list')) {
            target_class.class_methods_list = [];
        }

        target_class.class_methods_list.push(key);
    };
}

// Provides access to the computed variable in the html
export function computed() {
    return function(target: any, key: string) {
        let target_class = target.constructor as typeof VueItem;

        if (!target_class.hasOwnProperty('class_computed_list')) {
            target_class.class_computed_list = [];
        }

        target_class.class_computed_list.push(key);
    };
}


export default abstract class VueComponent extends VueItem {
    static $inject = [];

    protected constructor(component, ...services) {
        super();

        this.component = component;
        this.setupDataBindings();
    }
}
