import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {Services} from './Services';
import UserProfileService from '../../apps/userprofile/services/UserProfileService';

// Fallback for when local and session storage is not available for whatever reason
class InstanceStorage implements Storage {
    data: Map<string, string>;

    constructor() {
        this.data = new Map();
    }

    get length() {
        return Object.keys(this.data).length;
    }

    clear(): void {
        this.data = new Map();
    }

    getItem(key: string): string | null {
        return this.data.get(key);
    }

    key(index: number): string | null {
        return Object.keys(this.data)[index];
    }

    removeItem(key: string): void {
        this.data.delete(key);
    }

    setItem(key: string, value: string): void {
        this.data.set(key, value);
    }
}

type StorageObject = {
    expiration: Date,
    value: any,
    user: number
}

export class LocalStorage extends EventDispatcher {
    private readonly storage: Storage;
    private readonly prefix = 'sls-';

    constructor() {
        super();

        if (this.storageAvailable('localStorage')) {
            this.storage = window.localStorage;
        }
        else if (this.storageAvailable('sessionStorage')) {
            this.storage = window.sessionStorage;
        }
        else {
            console.warn('No local storage is available. Objects will only be saved within the session.');
            this.storage = new InstanceStorage();
        }

        setTimeout(() => {
            this.clearExpired();
        });
    }

    public keys() {
        let keys;
        if (this.storage instanceof InstanceStorage) {
            keys = Object.keys(this.storage.data);
        }
        else {
            keys = Object.keys(this.storage);
        }

        return [...keys].filter(v => v.startsWith(this.prefix)).map(v => v.slice(4));
    }

    private formatKey(key) {
        return `${this.prefix}${key}`;
    }

    public get(key: string) {
        if (!key) {
            return;
        }

        key = this.formatKey(key);
        let data = this.storage.getItem(key);

        let parsed: StorageObject;
        try {
            parsed = JSON.parse(data);

            if (parsed.expiration) {
                parsed.expiration = new Date(parsed.expiration);
            }
        }
        catch (e) {
            return null;
        }

        if (parsed.expiration && parsed.expiration.getTime() < new Date().getTime()) {

            this.storage.removeItem(key);
            return null;
        }

        if (parsed.user != Services.get<UserProfileService>('UserProfileService').user?.id) {
            return null;
        }

        return parsed.value;
    }

    public set(key: string, value: any, expiration?: Date) {
        if (!key) {
            return;
        }

        if (!expiration) {
            // Store things by default for only 30 days, so it doesn't build up too much
            expiration = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)) // 30 days;
        }

        key = this.formatKey(key);

        let data: StorageObject = {
            value: value,
            expiration: expiration,
            user: Services.get<UserProfileService>('UserProfileService').user?.id
        }

        this.storage.setItem(key, JSON.stringify(data));
    }

    public remove(key: string) {
        if (!key) {
            return;
        }

        this.storage.removeItem(this.formatKey(key));
    }

    public clearExpired() {
        for (const key of this.keys()) {
            this.get(key);
        }
    }

    private storageAvailable(type) {
        let storage;
        try {
            storage = window[type];
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return false;
        }
    }
}