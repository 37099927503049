export const html:string = `
<div id="account-guest">
    <div class="col-xs-12">
        <div id="guestForm">
            <div class="form-group">
                <span class="modal-title" id="loginModal">Guest Checkout</span>
                <span class="modal-title-helper">Place order without an account.</span>
            </div>
            <error-list field="unexpected" :errors="errors.loginForm"></error-list>
            <div class="form-group clearfix">
                <h5>Email Address</h5>
                <error-list field="email" :errors="errors"></error-list>
                <input type="email" class="form-control" name="email" v-model="email" autocomplete="username email" @keyup.enter="sendRequest()" required>
                <span class="pull-right guest-help">We'll confirm your order at this email address.</span>
            </div> 
            <error-list field="__all__" :errors="errors.loginForm"></error-list>
            <div class="form-group row">
                <div class="col-xs-6"></div>
                <div class="col-xs-6">
                    <button class="btn btn-ghost pull-right" @click="sendRequest()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
`;
