import {ProcessingUser} from '../models/ProcessingUser';
import APIResponse from '../../../../../common/ts/core/models/APIResponse';
import {Services} from '../../../../../common/ts/core/services/Services';

export default class ProcessingUserService {
    public users: APIResponse<ProcessingUser>;
    public user: ProcessingUser;

    constructor() {
        let user_element = document.getElementById("user-data");
        this.users = Services.get<typeof ProcessingUser>('ProcessingUser').objects.from(
            JSON.parse(decodeURIComponent(escape(atob(user_element.textContent.replace(/(\r\n\t|\n|\r\t|\s)/gm, '')))))
        );

        if (this.users.items.length > 0) {
            this.user = this.users.items[0];
        }
    }
}
