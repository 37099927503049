import {Services} from './Services';

export class Route {
    name: string;
    path: string;

    constructor(name, path) {
        this.name = name;
        this.path = path;
    }

    get uri_regexp() {
        // Turn a uri into a regular expression so that we can test the current url against it.
        // /dashboard/marketplace/products/:id/
        // ^/dashboard/marketplace/products/[a-zA-Z0-9_-]+/$


        // Match all uri parts (ex: /:id/)
        let uri_placeholder_regex = /\/:[a-zA-Z0-9_]+\//g;

        // Change all uri parts to match all alpha numeric and '_' '-' characters/
        // Start with ^ and end with $ so that / does not match /foo/
        return new RegExp('^' + this.path.split(uri_placeholder_regex).join('\/[a-zA-Z0-9_-]+\/') + '$');
    }

    get params(): Map<string, string> {
        // Return type is a map to prevent against prototype pollution

        let path = window.location.pathname;

        let path_parts = path.split('/');
        let route_parts = this.path?.split('/');

        if (route_parts.length != path_parts.length) {
            return new Map();
        }

        let data = new Map();
        for (let i = 0; i < route_parts.length; i++) {
            if (route_parts[i] != path_parts[i] && route_parts[i].indexOf(':') == 0) {
                // Key defined in code so its safe
                data.set(route_parts[i].replace(':', ''), path_parts[i]);
            }
        }

        let url = new URL(String(window.location));
        for (const parameter of url.searchParams.entries()) {
            let key = parameter[0];
            let value = parameter[1];

            if (key && value && !data.get(key)) {
                // Key is from user input, treat it as unsafe and try to block common attack vectors down stream
                if (!key) {
                    continue;
                }

                // Prevent any non alpha numeric or '-' or '_' characters
                let regexp = /^[a-zA-Z0-9-_]+$/;
                if (key.search(regexp) === -1) {
                    continue;
                }

                // Restrict key words to prevent potential prototype pollution attacks
                if (key.indexOf('proto') != -1 || key.indexOf('constructor') != -1 || key.indexOf('javascript') != -1) {
                    continue;
                }

                data.set(key, value);
            }
        }

        return data;
    }
}

export default class RouteService {
    public routes: Route[];

    constructor() {
        this.routes = Services.get<Route[]>('RouteList');
    }

    public keywordArgumentList(): string[] {
        let items  = [];
        for (const route of this.routes) {
            for (const item of this.parsePath(route.path)) {
                items.push(item);
            }
        }

        return items;
    }

    public keywordArgumentsFromName(name): string[] {
        let route = this.getRouteFromName(name);

        if (route) {
            return this.parsePath(route.path);
        }

        return null;
    }

    get current() {
        let path = window.location.pathname;
        for (const item of this.routes) {
            if (item.uri_regexp.test(path)) {
                return item;
            }
        }

        console.warn('Unable to find a route for the current url. Make sure its registered.')
        return new Route(window.location.pathname, window.location.pathname);
    }

    private parsePath(path): string[] {
        let items = [];

        let parts = path.split('/');
        for (const part of parts) {
            if (part.indexOf(':') === 0) {
                items.push(part.split(':')[1]);
            }
        }

        return items;
    }

    public getRouteFromName(name): Route {
        for (const item of this.routes) {
            if (item.name === name) {
                return item;
            }
        }

        return null;
    }

    get params(): URLSearchParams {
        return new URL(window.location.href).searchParams;
    }

    updateUrlQuery(obj: any) {
        let url = new URL(document.location.origin + document.location.pathname);
        for (const key of Object.keys(obj)) {
            let value = obj[key];
            if (value instanceof Date) {
                value = value.toISOString()
            }
            url.searchParams.append(key, value);
        }

        history.pushState(obj, document.title, '?' + url.searchParams.toString())
    }
}
