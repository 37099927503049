export const html: string = `
<div class="section-header row">
    <div class="col-xs-12">
        <h1>New Work Orders</h1>
    </div>
</div>

<div class="main-container col-full" v-if="work_orders.$resolved && work_orders.items.length != 0">
    <work-order-item :work-order="item" v-for="item in work_orders.items"></work-order-item>
    
    <div class="col-xs-12">
        <paginator :total="work_orders.totalCount" :limit="limit" :page="page" :url="paginator_url" class="pull-right"></paginator>
    </div>
    
    <div class="col-xs-12 work-order-footer-notes">
        <span>Please make sure the size on the work order is set to the size of the Processed file BEFORE uploading.</span>
    </div>
</div>

<div class="main-container" v-if="no_new_items">
    No new work orders, please check again later
</div>

<div class="main-container" v-if="work_orders.$resolved && work_orders.items.length == 0">
    <button class="btn btn-lg btn-success" @click="acceptNew($event)">Accept New Work Order</button>
</div>

<upload-work-order></upload-work-order>
<view-work-order-modal></view-work-order-modal>
<view-work-order-note></view-work-order-note>
<view-work-order-customer-image></view-work-order-customer-image>
<view-work-order-processed-image></view-work-order-processed-image>
<reject-work-order></reject-work-order>
`;