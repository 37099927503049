import {Component, DebuggerEvent} from 'vue';
import {EventDispatcher} from 'simple-ts-event-dispatcher';
import APIResponse from '../models/APIResponse';
import {Model} from '../models/Model';
import * as Sentry from '@sentry/browser';
import {kebabize} from '../utils/utils';
import VueMixin from './VueMixin';
import {Services} from '../services/Services';

export interface PropItem {
    name?: string;
    emit?: boolean;
    watch?: boolean;
}

export type RefAction = (el: HTMLElement, scope: VueItem, key: string) => void;

export interface DataItem {
    ref?: RefAction
}

export default abstract class VueItem extends EventDispatcher {
    /*
      The component instance that this object is linked to.
     */
    protected component: any;

    /*
      A list/hash of attributes that are exposed to accept data from the parent component. It has an Array-based simple
      syntax and an alternative Object-based syntax that allows advanced configurations such as type checking, custom
      validation and default values.
     */
    static class_props_list: PropItem[] = [];

    /*
      A list of function names to use with the vue computed, methods and data. The names provided in these will
      setup a binding to the controller.
     */
    static class_emits_list: string[] = [];
    static class_computed_list: string[] = [];
    static class_methods_list: string[] = [];
    static class_bound_data: string[] = [];
    static class_ref_data: {[key: string]: RefAction};

    static get methods_list(): string[] {
        if (this == VueItem) {
            return this.class_methods_list;
        }

        return [... new Set([...Object.getPrototypeOf(this).methods_list, ...this.class_methods_list])];
    }

    static get computed_list(): string[] {
        if (this == VueItem) {
            return this.class_computed_list;
        }

        return [...new Set([...Object.getPrototypeOf(this).computed_list, ...this.class_computed_list])];
    }

    static get emits_list(): string[] {
        if (this == VueItem) {
            return this.class_emits_list;
        }

        return [...new Set([...Object.getPrototypeOf(this).emits_list, ...this.class_emits_list])];
    }

    static get bound_data(): string[] {
        if (this == VueItem) {
            return this.class_bound_data;
        }

        return [...new Set([...Object.getPrototypeOf(this).bound_data, ...this.class_bound_data])];
    }

    static get bound_ref_data(): {[key: string]: RefAction} {
        if (this == VueItem) {
            return this.class_ref_data;
        }

        return {...Object.getPrototypeOf(this).bound_ref_data, ...this.class_ref_data};
    }

    static get props_list(): PropItem[] {
        if (this == VueItem) {
            return this.class_props_list;
        }

        let unique_parent_set = [];
        for (const item of Object.getPrototypeOf(this).props_list) {
            let found = false;
            for (const current of this.class_props_list) {
                if (item.name == current.name) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                unique_parent_set.push(item);
            }
        }

        return [...unique_parent_set, ...this.class_props_list];
    }

    /*
     Composition / limited multiple inheritance support. This is similar to the vue composition api.

     This allows components to share all the data items and exposes the html to all of the methods, computed items ect.
     */
    static mixins: typeof VueMixin[];
    public mixin_instances = {};

    /*
        Vue locks down the $data object in development mode until the data function is called so we need to store the
        data locally until we can give it to vue.
     */
    private starting_data_set = {};
    private data_init: boolean = false;

    /*
      Called synchronously immediately after the instance has been initialized, before data observation and
      event/watcher setup.
     */
    protected constructor() {
        super();

        if ((this.constructor as any).mixins) {
            for (const mixin_class of (this.constructor as any).mixins) {
                this.mixin_instances[mixin_class] = new mixin_class(this, this.component, ...Services.getList(mixin_class.$inject))
            }
        }
    }

    findFunctionInstance(name) {
        if (typeof this[name] === 'function') {
            return this;
        }

        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            let instance_check = mixin.findFunctionInstance(name);
            if (instance_check) {
                return instance_check;
            }
        }

        return null;
    }

    protected setupDataBindings() {
        this.setupDataList();
        this.setupEmitsList();
        this.setupPropsList();

        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.bindComponent(this.component);
        }
    }

    setupDataList() {
        if (!(this.constructor as any).bound_data) {
            return
        }

        let bound_ref_data =  (this.constructor as any).bound_ref_data;

        for (const key of (this.constructor as any).bound_data) {
            // We are overriding the variable and wrapping a getter/setter so we need to save the value that's set
            // and store it again after replacing the variable
            const current_value = this[key];
            const ref_action: RefAction = bound_ref_data[key];

            const getter = () => {
                return this.$data[key];
            };
            const setter = (v) => {
                /*
                    Vue can have syncing issues with complex objects. If we still want to use APIResponse or other
                    complex objects in $data we need to notify vue when a update was made without it knowing.
                    To do this call $forceUpdate on a trigger. It will force a rerender of everything in the
                    component it and all of its children. Its inefficient and not the "vue" way of doing things
                    but not being able to use model or api response objects is a huge drawback that I would like
                    to avoid.
                    Update:
                    Look into wrapping object with reactive instead so it only updates that object
                    https://v3.vuejs.org/guide/reactivity.html#proxied-objects
                 */
                if (v instanceof APIResponse) {
                    if (!v.$resolved && !v.$promise.isResolved()) {
                        v.$promise.then(() => {
                            this.component.$forceUpdate();
                        })
                    }
                }

                if (v instanceof Model) {
                    if (!v.$resolved && !v.$promise.isResolved()) {
                        v.$promise.then(this.$forceUpdate.bind(this));
                    }
                }

                if (v instanceof EventDispatcher) {
                    v.bind('sync', () => {
                        this.component.$forceUpdate();
                    }, this);
                    this.once('$destroy', () => {
                        v.unbindWithContext('sync', this);
                    });
                }

                if (ref_action) {
                    if (this.component.$refs[key]) {
                        ref_action(this.component.$refs[key], this, key);
                    }
                }

                this.$data[key] = v;
            };

            Object.defineProperty(this, key, {
                get: getter,
                set: setter,
                enumerable: false,
                configurable: false
            });

            // Store the value now that we have it
            this[key] = current_value;
        }
    }

    setupEmitsList() {
        if (!(this.constructor as any).emits_list) {
            return
        }

        for (const key of (this.constructor as any).emits_list) {
            const function_call = (...args) => {
                this.$emit(kebabize(key), ...args)
            }

            Object.defineProperty(this, key, {
                value: function_call,
                enumerable: false,
                configurable: false
            });
        }
    }

    setupPropsList() {
        if (!(this.constructor as any).props_list) {
            return;
        }
        for (const prop of (this.constructor as any).props_list) {
            const key = prop.name;

            let setupBinding = (v) => {
                /*
                    Look into making these more extendable or generic so that asynchronous items outside of this package
                    can also correctly update.
                 */
                if (v instanceof EventDispatcher) {
                    v.bind('sync', () => {
                        this.component.$forceUpdate();
                    }, this);
                    this.once('$destroy', () => {
                        v.unbindWithContext('sync', this);
                    });
                }

                if (v instanceof Model) {
                    if (!v.$resolved && !v.$promise.isResolved()) {
                        v.$promise.then(this.$forceUpdate.bind(this));
                    }
                }

                if (v instanceof APIResponse) {
                    if (!v.$resolved && !v.$promise.isResolved()) {
                        v.$promise.then(this.$forceUpdate.bind(this));
                    }
                }
            }

            setupBinding(this.component.$props[key]);

            const getter = () => {
                return this.component.$props[key];
            };
            const setter = (v) => {
                // Can't directly set props, v-model + emit should update the value correctly
                // this.component.$props[key] = v;

                setupBinding(v);

                // Two way bindings are expensive so lets only allow it if its explicitly set
                if (prop.emit) {
                    // Emit the change so anything that's watching with v-model:prop_name will update
                    // https://v3.vuejs.org/guide/component-custom-events.html#v-model-arguments
                    this.component.$emit(`update:${key}`, v);
                }
            };

            Object.defineProperty(this, key, {
                get: getter,
                set: setter,
                enumerable: false,
                configurable: false
            });
        }

    }

    /*
      Called synchronously immediately after the instance has been initialized, before data observation and
      event/watcher setup.
     */
    public beforeCreate() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.beforeCreate();
        }
    }

    /*
      The function that returns a data object for the component instance. In data, we don't recommend to observe objects
      with their own stateful behavior like browser API objects and prototype properties. A good idea would be to have
      here just a plain object that represents component data.

      Once observed, you can no longer add reactive properties to the root data object. It is therefore recommended to
      declare all root-level reactive properties upfront, before creating the instance.

      After the instance is created, the original data object can be accessed as vm.$data. The component instance also
      proxies all the properties found on the data object, so vm.a will be equivalent to vm.$data.a.
      Properties that start with _ or $ will not be proxied on the component instance because they may conflict with
      Vue's internal properties and API methods. You will have to access them as vm.$data._property.
     */
    public data(): object {
        this.data_init = true;

        let starting_data = this.starting_data_set;

        // No longer need the starting data set, it will be transferred to the vue component
        this.starting_data_set = null;

        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            starting_data = {...mixin.data(), ...starting_data}
        }

        return starting_data;
    }

    /*
      Called synchronously after the instance is created. At this stage, the instance has finished processing the
      options which means the following have been set up: data observation, computed properties, methods, watch/event
      callbacks. However, the mounting phase has not been started, and the $el property will not be available yet.
     */
    public created(): void {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.created();
        }
    }

    /*
      Called right before the mounting begins: the render function is about to be called for the first time.
     */
    public beforeMount() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.beforeMount();
        }
    }

    /*
      Called after the instance has been mounted, where element, passed to Vue.createApp({}).mount() is replaced by
      the newly created vm.$el. If the root instance is mounted to an in-document element, vm.$el will also be
      in-document when mounted is called.
      Note that mounted does not guarantee that all child components have also been mounted. If you want to wait
      until the entire view has been rendered, you can use vm.$nextTick inside of
     */
    public mounted() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.mounted();
        }
    }

    /*
      Called when data changes, before the DOM is patched. This is a good place to access the existing DOM before an
      update, e.g. to remove manually added event listeners.
      This hook is not called during server-side rendering, because only the initial render is performed server-side.
     */
    public beforeUpdate() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.beforeUpdate();
        }
    }

    /*
    Called after a data change causes the virtual DOM to be re-rendered and patched.
    The component's DOM will have been updated when this hook is called, so you can perform DOM-dependent operations
    here. However, in most cases you should avoid changing state inside the hook. To react to state changes, it's
    usually better to use a computed property or watcher instead.
    Note that updated does not guarantee that all child components have also been re-rendered. If you want to wait
    until the entire view has been re-rendered, you can use vm.$nextTick inside of updated
    Example:
    updated() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been re-rendered
      })
    }
     */
    public updated() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.updated();
        }
    }

    /*
      Called when a kept-alive component is activated.
     */
    public activated() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.activated();
        }
    }

    /*
      Called when a kept-alive component is deactivated.
     */
    public deactivated() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.deactivated();
        }
    }

    /*
      Called right before a component instance is unmounted. At this stage the instance is still fully functional.
     */
    public beforeUnmount() {
        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.beforeUnmount();
        }
    }

    /*
      Called after a component instance has been unmounted. When this hook is called, all directives of the
      component instance have been unbound, all event listeners have been removed, and all child component
      instance have also been unmounted.
     */
    public unmounted() {
        this.trigger('$destroy');

        for (const mixin of Object.values<VueMixin>(this.mixin_instances)) {
            mixin.unmounted();
        }
    }

    /*
      Called when an error from any descendent component is captured. The hook receives three arguments: the error,
      the component instance that triggered the error, and a string containing information on where the error was
      captured. The hook can return false to stop the error from propagating further.
      Error Propagation Rules:
      By default, all errors are still sent to the global config.errorHandler if it is defined, so that these errors
      can still be reported to an analytics service in a single place.
      If multiple errorCaptured hooks exist on a component's inheritance chain or parent chain, all of them will be
      invoked on the same error.
      If the errorCaptured hook itself throws an error, both this error and the original captured error are sent to
      the global config.errorHandler.
      An errorCaptured hook can return false to prevent the error from propagating further. This is essentially
      saying "this error has been handled and should be ignored." It will prevent any additional errorCaptured
      hooks or the global config.errorHandler from being invoked for this error.
     */
    public errorCaptured(err: Error, instance: Component|any, info: string): boolean | void {
        console.error(err);
        Sentry.captureException(err);
        return true;
    }

    /*
      Called when virtual DOM re-render is tracked. The hook receives a debugger event as an argument. This event
      tells you what operation tracked the component and the target object and key of that operation.
     */
    public renderTracked(e: DebuggerEvent): void {
    };

    /*
      Called when virtual DOM re-render is triggered.Similarly to renderTracked, receives a debugger event as an
      argument. This event tells you what operation triggered the re-rendering and the target object and key of
      that operation.
     */
    public renderTriggered(e: DebuggerEvent): void {
    };

    /*
      The data object that the component instance is observing. The component instance proxies access to the properties
      on its data object.
     */
    get $data() {
        if (this.data_init) {
            return this.component.$data;
        }
        else {
            return this.starting_data_set;
        }
    }

    get $props() {
        return this.component.$props;
    }

    get $el() {
        return this.component.$el;
    }

    get $options() {
        return this.component.$options;
    }

    get $parent() {
        return this.component.$parent;
    }

    get $root() {
        return this.component.$root;
    }

    get $slots() {
        return this.component.$slots;
    }

    get $refs() {
        return this.component.$refs;
    }

    get $attrs() {
        return this.component.$attrs;
    }

    /*
      Watch a reactive property or a computed function on the component instance for changes. The callback gets
      called with the new value and the old value for the given property. We can only pass top-level data, prop,
      or computed property name as a string. For more complex expressions or nested properties, use a function
      instead.
     */
    get $watch() {
        return this.component.$watch;
    }

    /*
      Trigger an event on the current instance. Any additional arguments will be passed into the listener's callback function.
     */
    get $emit() {
        return this.component.$emit;
    }

    /*
      Force the component instance to re-render. Note it does not affect all child components, only the instance itself
      and child components with inserted slot content.
     */
    get $forceUpdate() {
        return this.component.$forceUpdate;
    }

    /*
      Defer the callback to be executed after the next DOM update cycle. Use it immediately after you've changed some
      data to wait for the DOM update. This is the same as the global nextTick, except that the callback's this context
      is automatically bound to the instance calling this method.
     */
    get $nextTick() {
        return this.component.$nextTick;
    }
}
