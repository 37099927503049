export const html: string = `
<div class="dropdown generic-dropdown" v-dropdown="true">
    <button class="btn dropdown-toggle dropdown-ghost btn-default" type="button" :class="{'search-dropdown': search}">
        <div v-if="html" class="name text default-display-item" :class="{placeholder: getSelectedName(selected) == placeholder}" v-html="getSelectedName(selected)"></div>
        <div v-if="!html" class="name text default-display-item" :class="{placeholder: getSelectedName(selected) == placeholder}">{{ getSelectedName(selected) }}</div>
        <input type="text" class="dropdown-search-input" v-model="query" v-on:keydown.down="focusFirstListElement($event)" :placeholder="getSelectedName(selected)" v-on:keydown.enter="autofillFromSelected($event)">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu" role="listbox">
        <li v-if="none != null && !hideNone && !noneLast" @click="select(null)" role="option">
            <div class="text">
                <input type="checkbox" :checked="isSelected(null)">
                <span v-if="html" v-html="getName(null)"></span>
                <span v-if="!html">{{ getName(null) }}</span>
            </div>
        </li>
        <li v-for="item in items" @click="select(item)" role="option" tabindex="0" v-on:keydown.enter="select(item);" v-on:keydown.up="tabUp($event)" v-on:keydown.down="tabDown($event)" v-show="display(item)">
            <div class="text">
                <input type="checkbox" :checked="isSelected(item)">
                <span v-if="html" v-html="getName(item)"></span>
                <span v-if="!html">{{ getName(item) }}</span>
            </div>
        </li>
        <li v-if="none != null && !hideNone && noneLast" @click="select(null)" role="option">
            <div class="text">
                <input type="checkbox" :checked="isSelected(null)">
                <span v-if="html" v-html="getName(null)"></span>
                <span v-if="!html">{{ getName(null) }}</span>
            </div>
        </li>
        <li v-show="items && items.length > 0 && !anyVisible()" class="no-results-warning">
            <div class="text">
                <span>No Results Found</span>
            </div>
        </li>
    </ul>
</div>
`;
