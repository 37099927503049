import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {User} from './User';
import {UserAddress} from './UserAddress';
import {UserInStoreCreditTransaction} from './UserInStoreCreditTransaction';
import {WholesaleAccount} from './WholesaleAccount';
import {WholesaleApplication} from './WholesaleApplication';
import {WholesaleMembership} from './WholesaleMemebership';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        'User': User,
        'UserAddress': UserAddress,
        'UserInStoreCreditTransaction': UserInStoreCreditTransaction,
        'WholesaleAccount': WholesaleAccount,
        'WholesaleApplication': WholesaleApplication,
        'WholesaleMembership': WholesaleMembership
    };

    Model.registerModels(models);
}
