export const html: string = `
<div id="view-large-processed-image-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="view-large-processed-image-modal">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel"><i class="fa fa-eye"></i>&nbsp;View Processed File</h4>
            </div>
            <div class="modal-body clearfix" v-if="work_order_service.work_order">
                <div class="col-xs-6">
                    <h4>Processed File</h4>
                    <img :src="work_order_service.work_order.active_file?.raster ? work_order_service.work_order.active_file.raster : '/static/img/processing/no-processed-file.svg'">
                </div>
                <div class="col-xs-6">
                    <h4>Customer Version</h4>
                    <img :src="work_order_service.work_order.active_file?.customer_raster ? work_order_service.work_order.active_file.customer_raster : '/static/img/processing/no-processed-file.svg'">
                </div>
            </div>
        </div>
    </div>
</div>
`;