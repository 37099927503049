import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class WholesaleAccount extends Model {
    static override uri: string = '/profile/api/user/wholesale-account/:id/';
    static override objects: APIResource<WholesaleAccount>;

    @field()
    name: string;

    @field()
    enabled: boolean;

    @field()
    price_modifier: number;

    @field()
    icon: string;

    get display_name() {
        return `${this.name} (${100 - (this.price_modifier * 100)}%)`
    }
}