import {html} from './UserDetail.html';
import {ProcessingUser} from '../../models/ProcessingUser';
import ButtonProcessingState from '../../../../../../common/ts/core/utils/ButtonProcessingState';
import MessageList from '../../../../../../common/ts/core/utils/MessageList';
import {Services} from '../../../../../../common/ts/core/services/Services';
import VueComponent, {data, method} from '../../../../../../common/ts/core/adapters/VueComponent';
import IVueComponent from '../../../../../../common/ts/core/adapters/IVueComponent';
import RouteService from '../../../../../../common/ts/core/services/RouteService';
import {processRequestError} from '../../../../../../common/ts/core/utils/utils';

class UserDetailController extends VueComponent {

    @data()
    errors: MessageList;

    @data()
    success: string[];

    @data()
    user: ProcessingUser;

    constructor(component) {
        super(component);

        this.user = Services.get<typeof ProcessingUser>('ProcessingUser').objects.get({
            id: Services.get<RouteService>('RouteService').current.params.get('id')
        });
    }

    @method()
    save($event) {
        const state = new ButtonProcessingState($event);

        this.errors = new MessageList();
        this.success = [];

        state.process();

        this.user.save().then(() => {
            state.resolved();
            this.success.push('Successfully saved user.');
        },
        (error) => {
            state.resolved();

            this.errors = processRequestError(error);
            this.errors.add('__all__', 'Failed to save user.');
        });
    }
}

export default function UserDetail(): IVueComponent {
    return {
        controller: UserDetailController,
        template: html,
        tag: 'user-detail'
    };
}
