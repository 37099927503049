import MessageList from '../../utils/MessageList';
import {html} from './ErrorList.html';
import VueComponent, {computed, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';

export class ErrorListController extends VueComponent {
    @prop()
    errors: MessageList;

    @prop()
    field: string;

    @computed()
    filtered_errors(): string[] {
        if (!this.errors) {
            return [];
        }

        let errors = this.errors;
        if (!(this.errors instanceof MessageList)) {
            // A MessageList object should be used for error message handling.
            // There is some old code somewhere that isn't using it so lets try to convert it to the correct type.
            errors = new MessageList(this.errors);
        }

        let merged = []
        for (const field of this.field.split(',')) {
            merged = [...new Set([...merged, ...(errors.get(field) || [])])]
        }

        return merged
    }
}

export default function ErrorList(): IVueComponent {
    return {
        controller: ErrorListController,
        template: html,
        tag: 'error-list'
    };
}
