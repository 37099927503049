import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {UserAddress} from '../models/UserAddress';
import APIResponse from '../../../core/models/APIResponse';
import {Http} from '../../../core/services/Http';

export default class UserAddressService extends EventDispatcher {
    public static $inject: string[] = [
        '$http',
        'UserAddress'
    ];

    public addresses: APIResponse<UserAddress>;
    public countries: string[];

    private fetchingCountries: boolean;
    private statesMap: {};
    private loadedStatesMap: {};
    private assigned_user: number;

    constructor(
        private $http: Http,
        private userAddressModel: typeof UserAddress
    ) {
        super();
        this.fetchingCountries = false;
        this.statesMap = {};
        this.loadedStatesMap = {};
    }

    public assignUser(id) {
        if (this.assigned_user != id) {
            this.assigned_user = id;
            this.fetchAddresses(true);
        }
    }

    public unsetUser() {
        this.assigned_user = null;
        this.fetchAddresses(true);
    }

    public fetchAddresses(reload?) {
        if (!this.addresses || reload) {

            if (this.assigned_user) {
                this.addresses = this.userAddressModel.objects.filter({user: this.assigned_user, archived: false});
            }
            else {
                this.addresses = this.userAddressModel.objects.all();
            }

            this.addresses.$promise.then(() => {
                this.trigger('loaded:addresses');
            });
        }
    }

    public fetchCountries() {
        if (this.countries) {
            return;
        }

        this.countries = [];
        this.$http.request({
            method: 'GET',
            url: '/delivery/api/get-countries/'
        }).then((response: any) => {
            this.countries = response.data.countries;
            this.trigger('loaded:countries');
            this.trigger('sync');
        }, (error) => {
            this.countries = [];
            return error;
        });
    }

    setDefaultAddress(address, billing: boolean, shipping: boolean) {
        if (!address)
            return;

        this.$http.request({
            url: '/profile/api/set-default-address/',
            method: 'POST',
            data: {
                address: address.id,
                billing: billing,
                shipping: shipping
            }
        }).then((response) => {
            this.fetchAddresses(true);
        });
    }

    public statesFor(address) {
        if (address) {
            if (!this.statesMap[address.country] && !this.loadedStatesMap[address.country]) {
                this.fetchStates(address.country);
            }

            return this.statesMap[address.country];
        }

        return [];
    }

    public fetchStates(country: string): void {
        if (this.statesMap[country]) {
            return this.statesMap[country]
        }

        this.statesMap[country] = [];
        this.loadedStatesMap[country] = true;

        this.$http.request({
            method: 'GET',
            url: `/delivery/api/get-states/`,
            params: {
                country: country
            }
        }).then((response: any) => {
            this.statesMap[country] = response.data.states;
            this.trigger('sync');
            this.trigger('loaded:states');
        });
    }
}
